<style lang="less" scoped>
.category-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}
.input {
  .input(30px);
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 0 10px;
}
.category-link-list {
  display: flex;
  flex-direction: column;
  margin: -5px 0;
}
.category-link {
  height: 28px;
  margin: 5px 0;
  border-radius: 8px;
  padding: 0 10px;
  line-height: 200%;

  &.active {
    background-color: var(--primary-2);
    font-weight: 700;
    color: var(--primary-7);
  }
  &:hover:not(.active) {
    background-color: #EEF2ED;
  }
}
</style>

<template lang="pug">
.category-container
  input.input(placeholder="보드 검색")
  .category-link-list
    router-link.category-link(to="/orders") 주문
    router-link.category-link(to="/orders/ordered") 주문: 주문됨
    router-link.category-link(to="/orders/preparing") 주문: 준비중
    router-link.category-link(to="/orders/packed") 주문: 출고 대기
    router-link.category-link(to="/orders/in-transit") 주문: 배송중
    router-link.category-link(to="/orders/delivered") 주문: 배송 완료
    router-link.category-link(to="/orders/canceled") 주문: 취소됨
    router-link.category-link(to="/days-off") 휴일
    router-link.category-link(to="/coupon-codes") 쿠폰 코드
    router-link.category-link(to="/items") 아이템
    router-link.category-link(to="/item-lists") 아이템 리스트
    router-link.category-link(to="/reviews") 리뷰
    router-link.category-link(to="/short-links") 단축 URL
    router-link.category-link(to="/questions") 고객문의
    router-link.category-link(to="/local-sellers") 판매자
    router-link.category-link(to="/local-seller-withdrawals") 판매자 인출
</template>

<script>
export default {
};
</script>
