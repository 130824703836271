<style lang="less" scoped>
.contents {
  flex: 1;
  padding: 0 10px;
  overflow: auto;
}
.input {
  .input();
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
}
.save-button {
  .button();
}
</style>

<template lang="pug">
.contents
  template(v-if="isMultiple")
    .form-group
      label.label 선택된 휴일 갯수
      input.input(:value="selectedItems.length", readonly)
    .form-group
      label.label 활성화
      st-select(v-model="multipleActivated")
        option(:value="true") true
        option(:value="false") false
  template(v-else-if="dayOff")
    .form-group
      label.label 날짜
      input.input(:value="dayOff._id", readonly)
    .form-group
      label.label 설명
      input.input(v-model="dayOff.note")
    .form-group
      label.label 활성화
      st-select(v-model="dayOff.activated")
        option(:value="true") true
        option(:value="false") false
footer.footer
  button.save-button(@click="submit") 저장하기
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';

dayjs.extend(localizedFormat);
dayjs.locale('ko');

export default {
  components: {
    StTextarea,
    StSelect,
  },
  emits: ['refresh'],
  props: {
    selectedItems: { type: Array, default: () => [] },
  },
  watch: {
    isMultiple(value) {
      if (!value) this.fetchDayOff();
    },
    selectedItemId() {
      this.fetchDayOff();
    },
  },
  computed: {
    isMultiple() {
      if (!this.selectedItems) return false;
      if (this.selectedItems.length === 1) return false;
      return true;
    },
    selectedItemId() {
      if (this.isMultiple) return null;
      return this.selectedItems[0]._id;
    },
  },
  data() {
    return {
      dayOff: null,
      multipleActivated: '',
    };
  },
  mounted() {
    if (!this.isMultiple) this.fetchDayOff();
  },
  methods: {
    setYYYYMMDD(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    async fetchDayOff() {
      if (!this.selectedItems[0]) return;
      try {
        const dayOffId = this.setYYYYMMDD(this.selectedItems[0]._id);
        const { data: dayOff } = await this.$http.get(`/crew-only/days-off/${dayOffId}`);
        this.dayOff = dayOff;
      } catch (e) {
        console.error(e);
      }
    },
    submit() {
      if (this.isMultiple) this.updateMultipleDayOffs();
      else this.updateDayOff();
    },
    async updateMultipleDayOffs() {
      try {
        const payload = { activated: this.multipleActivated };
        const dayOffIds = this.selectedItems.map((item) => this.setYYYYMMDD(item._id)).join(',');
        await this.$http.put('/crew-only/days-off', payload, { params: { ids: dayOffIds } });
        this.$message({ message: '선택된 휴일들이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        console.error(e);
      }
    },
    async updateDayOff() {
      try {
        await this.$http.put(`/crew-only/days-off/${this.setYYYYMMDD(this.dayOff._id)}`, this.dayOff);
        this.$message({ message: '휴일이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
