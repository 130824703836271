<style lang="less" scoped>
.st-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:hover:not(:focus) {
    .check-icon { border-color: var(--primary-4); }
  }
}
.check-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--primary-5);
  border-radius: 4px;
}
.input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);

  &.checked + .check-icon {
    &::after {
      content: "";
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border-radius: 2px;
      background-color: var(--primary-5);
    }
  }
}
</style>

<template lang="pug">
.st-checkbox(@click.stop="")
  .input(
    :class="{ checked: value }",
    @click="click",
  )
  i.check-icon
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
  },
  methods: {
    click() {
      this.$emit('update:visible', !this.value);
    },
  },
};
</script>
