import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
  const view = new Uint8Array(buf); // create uint8array as viewer
  for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xFF; // convert to octet
  return buf;
};

const exportExcel = (data) => {
  const wb = XLSX.utils.book_new();
  const newWorksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, newWorksheet, '시트');
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  const fileName = dayjs().format('YYYY-MM-DD');
  saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `싹뚝 ${fileName}.xlsx`);
};

export default exportExcel;
