<style lang="less" scoped>
.contents {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
</style>

<template lang="pug">
.contents
  template(v-if="isMultiple")
    .form-group
      label.label 선택된 리뷰 갯수
      input.input(:value="selectedItems.length", readonly)
    .form-group
      label.label 활성화
      st-select(v-model="multipleActivated")
        option(:value="true") true
        option(:value="false") false
  template(v-else-if="review")
    form-contents(
      :products="products",
      :item="review",
      @submit="submitReview",
      @remove="removeReview",
    )
</template>

<script>
import StSelect from '@/components/StSelect';
import FormContents from './FormContents';

export default {
  components: {
    StSelect,
    FormContents,
  },
  emits: ['refresh', 'clearSelected'],
  props: {
    selectedItems: { type: Array, default: () => [] },
    products: { type: Object, default: null },
  },
  watch: {
    isMultiple(value) {
      if (!value) this.fetchReview();
    },
    selectedItemId() {
      this.fetchReview();
    },
  },
  computed: {
    isMultiple() {
      if (!this.selectedItems) return false;
      if (this.selectedItems.length === 1) return false;
      return true;
    },
    selectedItemId() {
      if (this.isMultiple) return null;
      return this.selectedItems[0]._id;
    },
  },
  data() {
    return {
      review: null,
      multipleActivated: '',
    };
  },
  mounted() {
    if (!this.isMultiple) this.fetchReview();
  },
  methods: {
    async fetchReview() {
      if (!this.selectedItems[0]) return;
      try {
        const reviewId = this.selectedItems[0]._id;
        const { data: review } = await this.$http.get(`/crew-only/reviews/${reviewId}`);
        this.review = review;
      } catch (e) {
        console.error(e);
      }
    },
    async removeReview() {
      if (!window.confirm('리뷰를 삭제하시겠습니까?')) return;
      try {
        await this.$http.delete(`/crew-only/reviews/${this.review._id}`);
        this.$emit('clearSelected');
        this.$message({ message: '리뷰가 삭제되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    submitReview(changedReview) {
      if (this.isMultiple) this.updateMultipleReviews();
      else this.updateReview(changedReview);
    },
    async updateMultipleReviews() {
      try {
        const payload = { activated: this.multipleActivated };
        const itemIds = this.selectedItems.map((item) => item._id).join(',');
        await this.$http.put('/crew-only/reviews', payload, { params: { ids: itemIds } });
        this.$message({ message: '선택된 리뷰들이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    async updateReview(changedReview) {
      try {
        await this.$http.put(`/crew-only/reviews/${this.review._id}`, changedReview);
        this.$message({ message: '리뷰가 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
  },
};
</script>
