<style lang="less" scoped>
.contents {
  flex: 1;
  padding: 0 10px;
  overflow: auto;
}
</style>

<template lang="pug">
.contents
  .form-group
    label.label 아이템 리스트 갯수
    input.input(:value="totalCount", readonly)
</template>

<script>
export default {
  props: {
    totalCount: { type: Number, default: 0 },
  },
};
</script>
