<style lang="less" scoped>
.footer {
  position: relative;
  box-shadow: 0px -1px 0px #E6E8E7;
}
.footer-contents {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px;
}
.filter-message-box {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  background-color: var(--orange-2);
  box-shadow: 0px -1px 0px #E6E8E7;
  color: var(--orange-1);
  font-weight: bold;
}
.search-input {
  .input(30px);
  width: 180px;
  margin-right: auto;
  padding: 0 10px;
  border-radius: 8px;
}
.pagination {
  position: absolute;
  left: calc((100% - 100px) / 2);
  display: flex;
  height: 28px;
  background-color: var(--white-4);
  border-radius: 4px;
}
.pagination-button {
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  &.left { background-image: url(~@/assets/left.svg); }
  &.right { background-image: url(~@/assets/right.svg); }
}
.pagination-number {
  font-weight: bold;
  line-height: 28px;
  color: var(--primary-2);
  margin: 0 5px;
}
.footer-button-group {
  display: flex;
  align-items: center;
  &.margin-left {
    margin-left: 8px;
  }
}
.footer-button {
  .button();
  &:not(:first-child) {
    margin-left: 8px;
  }
}
</style>

<template lang="pug">
footer.footer
  .filter-message-box(v-if="filterLabel") {{ filterLabel }}
  .footer-contents
    input.search-input(
      type="search",
      placeholder="항목 검색",
      :value="q",
      @search="searchQ",
    )
    .pagination
      button.pagination-button.left(@click="fetchPreviousPage")
      p.pagination-number {{ paginationText }}
      button.pagination-button.right(@click="fetchNextPage")
    //- TODO: 리팩토링 필요
    .footer-button-group(v-if="hasFilter")
      button.footer-button(@click="openFilterDialog") 필터
      slot(name="footer-button-group")
    .footer-button-group(v-if="hasNewButton", :class="{ 'margin-left': hasFilter }")
      button.footer-button(@click="$emit('addNewItem')") 추가하기

filter-dialog(
  v-model="filterDialogVisibility",
  :listStatus="listStatus",
  :from="from",
  :to="to",
  @clear="$emit('clearFilter')",
  @submit="$emit('submitFilter', $event)",
)
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import FilterDialog from '@/components/FilterDialog';

dayjs.extend(localizedFormat);
dayjs.locale('ko');

const STATUS_MAP = {
  pending: '결제 대기',
  ordered: '주문됨',
  canceled: '취소됨',
  preparing: '준비중',
  packed: '출고 대기',
  'in-transit': '배송중',
  delivered: '배송 완료',
};

export default {
  components: {
    FilterDialog,
  },
  emits: [
    'clearFilter',
    'submitFilter',
    'addNewItem',
  ],
  props: {
    // TODO: 수정 필요
    hasFilter: { type: Boolean, default: true },
    hasNewButton: { type: Boolean, default: false },

    q: { type: String, default: '' },
    listStatus: { type: String, default: '' },
    from: { type: String, default: '' },
    to: { type: String, default: '' },
    offset: { type: [Number, String], default: 0 },
    limit: { type: Number, default: 50 },
    hasMoreItems: { type: Boolean, default: false },
  },
  computed: {
    filterLabel() {
      const textArr = [];
      if (this.listStatus) textArr.push(`상태: ${STATUS_MAP[this.listStatus]}`);
      if (this.from || this.to) {
        let fromText = '';
        let toText = '';
        let dateDividerText = '';
        if (this.from) fromText = ` ${dayjs(this.from).format('YY-MM-DD HH:mm:ss')}`;
        if (this.to) toText = ` ${dayjs(this.to).format('YY-MM-DD HH:mm:ss')}`;
        if (fromText && toText) dateDividerText = ' - ';
        textArr.push(`주문일시: ${fromText}${dateDividerText}${toText}`);
      }
      return textArr.join(', ');
    },
    paginationText() {
      return `${this.offset}-${Number(this.offset) + this.limit}`;
    },
  },
  data() {
    return {
      filterDialogVisibility: false,
    };
  },
  methods: {
    searchQ($event) {
      this.$emit('submitFilter', { q: $event.target.value });
    },
    fetchPreviousPage() {
      if (!this.offset) {
        this.$message.warning('이전 페이지가 없습니다.');
        return;
      }
      this.$emit('submitFilter', { offset: Number(this.offset) - this.limit });
    },
    fetchNextPage() {
      if (!this.hasMoreItems) {
        this.$message.warning('마지막 페이지 입니다.');
        return;
      }
      this.$emit('submitFilter', { offset: Number(this.offset) + this.limit });
    },
    openFilterDialog() {
      this.filterDialogVisibility = true;
    },
  },
};
</script>
