<style lang="less" scoped>
.contents {
  margin-top: 5px;
}
.input {
  .input();
}
</style>

<template lang="pug">
.description-image-item-container
  thumbnail-uploader(
    :id="`description-image-${index}`",
    :image="image",
    @upload="upload",
    @remove="$emit('remove')",
  )
  .contents
    st-textarea(
      :maxHeight="400",
      :modelValue="text",
      @update:modelValue="$emit('update:text', $event)")
  .contents
    st-select(:modelValue="column", @update:modelValue="$emit('update:column', $event)")
      option(:value="1") 1
      option(:value="2") 2
  .contents
    input.input(:modelValue="redirectUrl", @update:modelValue="$emit('update:redirectUrl', $event)")
</template>

<script>
import ThumbnailUploader from '@/components/ThumbnailUploader';
import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';

export default {
  components: {
    ThumbnailUploader,
    StTextarea,
    StSelect,
  },
  emits: ['upload', 'remove', 'update:text', 'update:column', 'update:redirectUrl'],
  props: {
    image: { type: String, default: '' },
    index: { type: Number, default: 0 },
    text: { type: String, default: '' },
    column: { type: Number, default: 0 },
    redirectUrl: { type: String, default: '' },
  },
  methods: {
    upload($event) {
      this.$emit('upload', $event);
    },
  },
};
</script>
