<style lang="less" scoped>
.contents {
  flex: 1;
  padding: 0 10px;
  overflow: auto;
}
.item-text {
  padding: 2px 5px;
  white-space: pre-line;
  line-height: 32px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
}
.save-button {
  .button();
}
</style>

<template lang="pug">
.contents
  template(v-if="isMultiple")
    .form-group
      label.label 선택된 판매자 인출 수
      input.input(:value="selectedItems.length", readonly)
  template(v-else-if="withdrawal")
    .form-group
      label.label 상태
      st-select(v-model="withdrawal.status")
        option(value="pending") 대기중
        option(value="processing") 처리중
        option(value="finished") 완료
        option(value="canceled") 취소
    .form-group
      label.label 통장 사본
      img(
        v-if="withdrawal.bankAccountImageUrl",
        :src="withdrawal.bankAccountImageUrl",
        width="250",
      )
    .form-group
      label.label 신분증 사본
      img(
        v-if="withdrawal.idCardImageUrl",
        :src="withdrawal.idCardImageUrl",
        width="250",
      )
footer.footer
  button.save-button(@click="updateWithdrawal") 저장하기
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';
import StSelect from '@/components/StSelect';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    StSelect,
  },
  emits: ['refresh', 'clearSelected'],
  props: {
    selectedItems: { type: Array, default: () => [] },
  },
  watch: {
    isMultiple(value) {
      if (!value) this.fetchWithdrawal();
    },
    selectedItemId() {
      this.fetchWithdrawal();
    },
  },
  computed: {
    isMultiple() {
      if (!this.selectedItems) return false;
      if (this.selectedItems.length === 1) return false;
      return true;
    },
    selectedItemId() {
      if (this.isMultiple) return null;
      return this.selectedItems[0]._id;
    },
  },
  data() {
    return {
      withdrawal: null,
      multipleActivated: '',
    };
  },
  mounted() {
    if (!this.isMultiple) this.fetchWithdrawal();
  },
  methods: {
    async fetchWithdrawal() {
      if (!this.selectedItems[0]) return;
      try {
        const withdrawalId = this.selectedItems[0]._id;
        const { data: withdrawal } = await this.$http.get(`/crew-only/local-seller-withdrawal-requests/${withdrawalId}`);
        this.withdrawal = withdrawal;
      } catch (e) {
        console.error(e);
      }
    },
    async updateWithdrawal() {
      try {
        await this.$http.put(`/crew-only/local-seller-withdrawal-requests/${this.withdrawal._id}`, this.withdrawal);
        this.$message({ message: '판매자가 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    displayDate(date) {
      if (!date) return '';
      return dayjs(date).format('LLL');
    },
  },
};
</script>
