<style lang="less" scoped>
.container {
  width: 21cm;
  height: 29.7cm;
  padding: 40px 0;
  margin: 60px auto;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}
.header {
  padding: 0 38px 0;
  width: 100%;
  margin-bottom: 30px;
}
.header-img {
  display: flex;
  justify-content: right;
}
.logo-img {
  width: 30px;
  height: 30px;
}
.header-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}
.header-descript {
  font-size: 10px;
  line-height: 160%;
  color: #353535;
}
.section {
  padding: 0 40px;
}
.section-contents-frame {
  width: 100%;
}
.second-contents {
  display: flex;
}
.component {
  width: 50%;
  letter-spacing: -0.1em;
  line-height: 160%;
  font-size: 12px;
  font-weight: 700;
}
.number {
  width: 50%;
  letter-spacing: -0.1em;
  line-height: 160%;
  font-size: 12px;
  font-weight: 700;
}
.component:last-child {
  font-size: 12px;
}
.number:last-child {
  font-size: 12px;
}
.second-table {
  width: 100%;
}
.second-tbody {
  width: 100%;
}
.second-tbody-tr {
  border-bottom: 1px solid #353535;
  width: 100%;
  display: flex;
}
.second-tbody-tr:nth-child(1) {
  border-top: 1px solid #353535;
}
.second-product-name-title {
  width: 50%;
  font-size: 12px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.second-product-data-title {
  width: 50%;
  font-size: 12px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.second-last-contents {
  border-top: 1px solid #353535;
  display: flex;
}
@media print {
  @page {
    size: A4;
    margin: 0;
    padding: 0;
  }
  .container {
    margin: 0;
    box-shadow: none;
  }
}
</style>

<template lang="pug">
.container
  .header
    .header-img
      img.logo-img(src="@/assets/minilogo.svg")
    h1.header-title 생산 계획서 ({{ currentDateTime }})
    p.header-descript(v-if="oIdsLabel") {{ oIdsLabel }}
    p.header-descript(v-else) {{ filterLabel }}
  .section
    .section-frame
      .section-contents-frame
        .second-contents
          p.component 구성 요소 이름
          p.number 갯수
        .second-table
          .second-tbody
            .second-tbody-tr(v-for="comopnent in productionPlan", :key="comopnent._id")
              p.second-product-name-title {{ comopnent.name }}
              p.second-product-data-title {{ comopnent.quantity }}
        .second-last-contents
          p.component 합계
          p.number {{ totalComponentsCount }}
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

dayjs.extend(localizedFormat);
dayjs.locale('ko');

const STATUS_MAP = {
  pending: '결제 대기',
  ordered: '주문됨',
  canceled: '취소됨',
  preparing: '준비중',
  packed: '출고 대기',
  'in-transit': '배송중',
  delivered: '배송 완료',
};

export default {
  computed: {
    oIdsLabel() {
      const { oIds } = this.$route.query;
      if (!oIds || oIds.length === 0) return '';
      if (_.isArray(oIds)) return oIds.join(', ');
      return oIds;
    },
    filterLabel() {
      const { q, listStatus, from, to } = this.$route.query;
      let statusText = '';
      let dateText = '';
      let fromText = '';
      let toText = '';
      let dateDividerText = '';
      let qText = '';
      if (listStatus) statusText = `상태: ${STATUS_MAP[listStatus]}`;
      if (from) fromText = ` ${dayjs(from).format('YY-MM-DD HH:mm:ss')}`;
      if (to) toText = ` ${dayjs(to).format('YY-MM-DD HH:mm:ss')}`;
      if (fromText || toText) dateText = ', 주문일시: ';
      if (fromText && toText) dateDividerText = ' - ';
      if (q) qText = ` 검색: ${q}`;
      return `${statusText}${dateText}${fromText}${dateDividerText}${toText}${qText}`;
    },
    totalComponentsCount() {
      if (!this.productionPlan) return 0;
      return this.productionPlan.reduce((acc, component) => acc + component.quantity, 0);
    },
  },
  data() {
    return {
      productionPlan: null,
      currentDateTime: dayjs().format('YY-MM-DD HH:mm'),
    };
  },
  async mounted() {
    await this.fetchProductionPlan();
    window.print();
  },
  methods: {
    async fetchProductionPlan() {
      const { oIds, q, listStatus: status, limit, offset, from: f, to: t } = this.$route.query;
      const from = f ? dayjs(f).toISOString() : '';
      const to = t ? dayjs(t).toISOString() : '';
      const params = { limit, offset, status, from, to, q };
      if (_.isArray(oIds)) params.ids = oIds.join(',');
      else if (_.isString(oIds)) params.ids = oIds;
      try {
        const { data: productionPlan } = await this.$http.get('/crew-only/daily-production-plans', { params });
        this.productionPlan = productionPlan;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
