<style lang="less" scoped>
.contents {
  flex: 1;
  padding: 0 10px 20px 10px;
  overflow: auto;
}
.input {
  .input();
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding: 0 8px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
}
.item-text {
  padding: 2px 5px;
  white-space: pre-line;
  line-height: 32px;
}
.save-button {
  .button();
}
</style>

<template lang="pug">
.contents
  template(v-if="isMultiple")
    .form-group
      label.label 선택된 주문 갯수
      input.input(:value="selectedItems.length", readonly)
    .form-group
      label.label 선택된 주문 금액의 합
      input.input(:value="displayPrice(totalPrice)", readonly)
    .form-group
      label.label 상태
      st-select(v-model="multipleStatus")
        option(value="ordered") 주문됨
        option(value="preparing") 준비중
        option(value="packed") 출고 대기
        option(value="in-transit") 배송중
        option(value="delivered") 배송 완료
  template(v-else-if="order")
    .form-group
      label.label 주문코드
      input.input(:value="order._id", readonly)
    .form-group(v-if="order.marketplaceType")
      label.label 마켓 플레이스
      input.input(:value="`${order.marketplaceType} (ID: ${order.marketplaceOrderId})`", readonly)
    .form-group
      label.label 주문한 사람 연락처
      input.input(:value="order.ordererPhoneNumber", readonly)
    .form-group
      label.label 주문한 사람 이름
      input.input(:value="order.ordererName || '이름 없음'", readonly)
    .form-group
      label.label 받는 사람 연락처
      input.input(v-model="order.phoneNumber", placeholder="받는 사람 연락처 입력")
    .form-group
      label.label 받는 사람 이름
      input.input(v-model="order.name", placeholder="받는 사람 이름 입력")
    .form-group
      label.label 우편번호
      input.input(:value="order.zipcode", readonly)
    .form-group
      label.label 도로명 주소
      input.input(:value="displayAddress(order.roadAddress)", readonly)
    .form-group
      label.label 지번 주소
      input.input(:value="displayAddress(order.jibunAddress)", readonly)
    .form-group
      label.label 상세 주소
      input.input(:value="displayAddress(order.detailAddress)", readonly)
    .form-group
      label.label 상태
      st-select(v-model="order.status", @input="changeStatus", :disabled="originOrder.status === 'canceled'")
        option(value="ordered") 주문됨
        option(value="canceled") 취소됨
        option(value="preparing") 준비중
        option(value="packed") 출고 대기
        option(value="in-transit") 배송중
        option(value="delivered") 배송 완료
        option(value="re-sent") 재배송
    template(v-if="order.status === 'canceled'")
      .form-group
        label.label 취소 유형
        st-select(v-model="order.reasonOfCancellationType")
          option(value="by-customer") 고객
          option(value="by-us") 회사
      .form-group
        label.label 취소 사유
        st-textarea(v-model="order.reasonOfCancellationDetail", placeholder="취소 사유 입력")
    template(v-if="order.status === 're-sent'")
      .form-group
        label.label 재배송 유형
        st-select(v-model="order.reasonOfResendingType")
          option(value="lost") 택배가 분실됨
          option(value="missing-component") 일부 구성품이 누락됨
          option(value="other-products") 다른 제품 도착
          option(value="quality-issue") 제품 품질 문제
          option(value="packaging-issue") 포장 또는 포장재(아이스팩 등)가 파손됨
          option(value="etc") 기타 사유
      .form-group
        label.label 재배송 사유
        st-textarea(v-model="order.reasonOfResendingDetail", placeholder="재배송 사유 입력")
    .form-group(v-if="deliveryTrackingNumbersText")
      label.label 송장번호
      st-textarea(:value="deliveryTrackingNumbersText", @input="editDeliveryTrackingNumbers($event.target.value)", placeholder="송장번호 입력")
    .form-group
      label.label 배송 요청사항
      st-textarea(v-model="order.deliveryRequest", placeholder="배송 요청사항")
    .form-group
      label.label 생산(예정)일
      input.input(v-model="order.manufacturingDate", type="date")
    .form-group
      label.label 도착(예정)일
      input.input(v-model="order.deliveryDue", type="date")
    .form-group
      label.label 금액
      input.input(:value="order.grandTotal", readonly)
    .form-group
      label.label 주문 상품
      p.item-text {{ orderItemsText }}
    .form-group(v-if="order.marketplaceOrderMetadata")
      label.label 마켓 플레이스 메타데이터
      p.item-text {{ order.marketplaceOrderMetadata }}
    .form-group
      label.label 메모
      input.input(v-model="order.memo", placeholder="메모 입력")
footer.footer
  button.save-button(@click="submit") 저장하기
</template>

<script>
import dayjs from 'dayjs';
import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';

dayjs.locale('ko');

export default {
  components: {
    StTextarea,
    StSelect,
  },
  emits: ['refresh'],
  props: {
    selectedItems: { type: Array, default: () => [] },
  },
  watch: {
    isMultiple(value) {
      if (!value) this.fetchOrder();
    },
    selectedItemId() {
      this.fetchOrder();
    },
  },
  computed: {
    isMultiple() {
      if (!this.selectedItems) return false;
      if (this.selectedItems.length === 1) return false;
      return true;
    },
    selectedItemId() {
      if (this.isMultiple) return null;
      return this.selectedItems[0]._id;
    },
    totalPrice() {
      if (!this.selectedItems) return false;
      return this.selectedItems.reduce((acc, curr) => acc + curr.grandTotal, 0);
    },
    orderItemsText() {
      if (!this.order) return '';
      return this.order.items
        .map((item) => `${item.name}, ${item.quantity}개, ${this.displayPrice(item.subTotal)}`)
        .join('\n');
    },
    deliveryTrackingNumbersText() {
      return this.order.deliveryTrackingNumbers.join('\n');
    },
  },
  data() {
    return {
      originOrder: null,
      order: null,
      multipleStatus: '',
    };
  },
  mounted() {
    if (!this.isMultiple) this.fetchOrder();
  },
  methods: {
    async fetchOrder() {
      if (!this.selectedItems[0]) return;
      try {
        const orderId = this.selectedItems[0]._id;
        const { data: order } = await this.$http.get(`/crew-only/orders/${orderId}`);
        this.originOrder = { ...order };
        this.order = order;
        if (this.order.userId) {
          const { data: user } = await this.$http.get(`/crew-only/users/${this.order.userId}`);
          this.order.ordererPhoneNumber = user.username;
          this.order.ordererName = user.name;
        }
      } catch (e) {
        console.error(e);
      }
    },
    editDeliveryTrackingNumbers(value) {
      this.order.deliveryTrackingNumbers = (value || '').split('\n');
    },
    displayPrice(price) {
      if (!price) return '';
      return `${Number(price).toLocaleString()}원`;
    },
    changeStatus($event) {
      const status = $event.target.value;
      if (status !== 'canceled') {
        this.order.reasonOfCancellationType = undefined;
        this.order.reasonOfCancellationDetail = undefined;
      } else if (status !== 're-sent') {
        this.order.reasonOfResendingType = undefined;
        this.order.reasonOfResendingDetail = undefined;
      }
    },
    displayAddress(roadAddress, jibunAddress) {
      if (!roadAddress) return jibunAddress;
      return `${roadAddress}`;
    },
    submit() {
      if (this.isMultiple) this.updateMultipleOrders();
      else this.updateOrder();
    },
    async updateMultipleOrders() {
      try {
        // TODO: 대량 처리 상태 변경시 수정 필요
        const payload = { status: this.multipleStatus };
        if (this.multipleStatus === 'canceled') {
          payload.reasonOfCancellationType = 'by-us';
          payload.beingRefunded = true;
        }
        const orderIds = this.selectedItems.map((item) => item._id).join(',');
        await this.$http.put('/crew-only/orders', payload, { params: { ids: orderIds } });
        this.$message({ message: '선택된 주문들이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        console.error(e);
      }
    },
    async updateOrder() {
      try {
        if (this.order.status === 'canceled') {
          if (!this.order.reasonOfCancellationType) this.order.reasonOfCancellationType = 'by-us';
          if (this.order.marketplaceType) this.$message({ message: '해당 마켓플레이스에서 환불 처리를 진행해주세요.' });
          else this.order.beingRefunded = true;
        }
        await this.$http.put(`/crew-only/orders/${this.order._id}`, this.order);
        this.$message({ message: '주문이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
