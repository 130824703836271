<style lang="less" scoped>
.thumbnail-uploader-container {
  display: flex;
  align-items: flex-end;
}
.empty-image-box {
  width: 100px;
  height: 100px;
  border: 1px solid #F6F6F3;
  background-color: #FAFAF9;
}
.button-group {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.button {
  font-size: 12px;
  &.success {
    .button();
  }
  &.danger {
    .button(@color: var(--orange-1));
  }
  &:not(:first-child) {
    margin-left: 5px;
  }
}
</style>

<template lang="pug">
.thumbnail-uploader-container
  img(v-if="image", :src="image", width="100", height="100")
  .empty-image-box(v-else)
  .button-group
    a.button.success(
      v-if="image",
      :href="image",
      target="_blank",
    ) 원본
    file-uploader.button.success(
      v-else,
      :id="id",
      @upload="upload",
    ) 이미지 업로드
    template(v-if="editable && image")
      file-uploader.button.success(
        :id="id",
        @upload="upload",
      ) 이미지 업로드
    template(v-if="!editable")
      button.button.danger(v-if="image", @click="$emit('remove')") 삭제
</template>

<script>
import FileUploader from '@/components/FileUploader';

export default {
  components: {
    FileUploader,
  },
  props: {
    id: { type: String, default: '' },
    image: { type: String, default: '' },
    editable: { type: Boolean, default: false },
  },
  emits: ['upload', 'remove'],
  methods: {
    upload($event) {
      this.$emit('upload', $event);
    },
  },
};
</script>
