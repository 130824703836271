<template lang="pug">
list-layout(
  :hasFilter="false",
  :hasNewButton="true",
  :loading="loading",
  :items="reviews",
  @fetchItems="fetchReviews",
)
  template(v-slot:table-columns)
    list-table-column(label="이름", prop="name")
    list-table-column(label="평점", prop="rating")
    list-table-column(label="플랫폼", prop="platform")
    list-table-column(label="수정일", prop="updatedAt", :filter="displayDate")
    list-table-column(label="리뷰 생성일(예약일)", prop="createdAt", :filter="displayDate")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems, clearSelected }")
    selected-contents(
      :selectedItems="selectedItems",
      :products="products",
      @refresh="fetchReviews({ isInit: true })",
      @clearSelected="clearSelected",
    )
  template(v-slot:new-contents)
    new-item-contents(
      :products="products",
      @refresh="fetchReviews({ isInit: true })",
    )
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';
import NewItemContents from './components/NewItemContents';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
    NewItemContents,
  },
  data() {
    return {
      loading: false,
      reviews: [],
      products: [],
      params: null,
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchReviews({ isInit, params }) {
      if (params) this.params = params;
      try {
        this.loading = true;
        const { data: reviews } = await this.$http.get('/crew-only/reviews', { params: this.params });
        if (isInit) this.reviews = reviews;
        else reviews.forEach((review) => this.reviews.push(review));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    displayDate(date) {
      if (!date) return '';
      return dayjs(date).format('LL');
    },
    async fetchProducts() {
      const { data: products } = await this.$http.get('/products');
      this.products = products;
    },
  },
};
</script>
