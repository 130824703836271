<template lang="pug">
list-layout(
  detailWidth="400px",
  :hasFilter="false",
  :hasNewButton="true",
  :loading="loading",
  :items="itemLists",
  @fetchItems="fetchItemLists",
)
  template(v-slot:table-columns)
    list-table-column(label="아이템 리스트 URL", prop="_id")
    list-table-column(label="제목", prop="title")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems, clearSelected }")
    selected-contents(
      :selectedItems="selectedItems",
      @refresh="fetchItemLists({ isInit: true })",
      @clearSelected="clearSelected",
    )
  template(v-slot:new-contents)
    new-item-contents(
      @refresh="fetchItemLists({ isInit: true })",
    )
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';
import NewItemContents from './components/NewItemContents';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
    NewItemContents,
  },
  data() {
    return {
      loading: false,
      itemLists: [],
    };
  },
  methods: {
    async fetchItemLists({ isInit, params }) {
      try {
        this.loading = true;
        const { data: itemLists } = await this.$http.get('/crew-only/item-lists', { params });
        if (isInit) this.itemLists = itemLists;
        else itemLists.forEach((review) => this.itemLists.push(review));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    displayDate(date) {
      if (!date) return '';
      return dayjs(date).format('LL');
    },
  },
};
</script>
