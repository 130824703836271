<style lang="less" scoped>
.list-layout-container {
  .l-list();
}
.list-layout-detail-container {
  .l-detail();
}
.v-enter-active {
  transition: opacity 0.3s ease-in-out;
}
.v-enter-from {
  opacity: 0;
}
</style>

<template lang="pug">
section.list-layout-container
  list-table(
    v-loading="loading",
    :items="items",
    :selectedItems="selectedItems",
    :isAllSelected="isAllSelected",
    @toggleAllSelectedItems="toggleAllSelectedItems",
    @toggleSelectedItem="toggleSelectedItem",
    @goToDetail="goToDetail",
    ref="listTable",
  )
    template(v-slot:table-columns)
      slot(name="table-columns")
  list-footer(
    :hasFilter="hasFilter",
    :hasNewButton="hasNewButton",
    :q="filterOptions.q",
    :listStatus="filterOptions.listStatus",
    :from="filterOptions.from",
    :to="filterOptions.to",
    :offset="filterOptions.offset",
    :limit="limit",
    :hasMoreItems="hasMoreItems",
    @clearFilter="clearFilter",
    @submitFilter="submitFilter",
    @addNewItem="addNewItem",
  )
    template(v-slot:footer-button-group)
      slot(
        name="footer-button-group",
        :filterOptions="filterOptions",
        :selectedItems="selectedItems",
      )
.list-layout-detail-container(
  v-if="detailVisible || newItemVisible",
  :style="detailStyleObj",
)
  template(v-if="detailVisible")
    list-detail
      template(v-slot:list)
        slot(
          name="detail-list-contents",
          :totalCount="totalCount",
        )
      template(v-slot:selected)
        slot(
          name="detail-selected-contents",
          :selectedItems="selectedItems",
          :clearSelected="clearSelected",
        )
  template(v-else-if="newItemVisible")
    slot(name="new-contents", @refresh="initItems")
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ko';

import ListDetail from './ListDetail';
import ListFooter from './ListFooter';
import ListTable from './ListTable';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

const LIMIT = 50;

const DEFAULT_FILTER_OPTIONS = {
  q: '',
  listStatus: '',
  offset: 0,
  from: '',
  to: '',
};

export default {
  components: {
    ListDetail,
    ListFooter,
    ListTable,
  },
  props: {
    hasFilter: { type: Boolean, default: true },
    hasNewButton: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    detailWidth: { type: String, default: '300px' },
  },
  emits: ['fetchItems'],
  provide() {
    return {
      selectedItems: this.selectedItems,
      items: this.items,
      filterOptions: this.filterOptions,
    };
  },
  watch: {
    // TODO: 리팩토링 필요 분리해야함
    '$route.params.listStatus': function watchRouteListStatus(v) {
      const [changedListStatus] = v;
      this.filterOptions.listStatus = changedListStatus;
    },
    filterOptions(v) {
      if (!v) return;
      const query = { ...v };
      Object.keys(v).forEach((key) => {
        if (v[key] === '' || v[key] === undefined || v[key] === 0) delete query[key];
      });
      this.$router.replace({ path: this.$route.path, query });
    },
  },
  computed: {
    detailVisible() {
      if (!this.selectedItems || this.selectedItems.length === 0) return false;
      return true;
    },
    isAllSelected() {
      if (!this.selectedItems || this.selectedItems.length === 0) return false;
      if (this.selectedItems.length === this.items.length) return true;
      return false;
    },
    hasMoreItems() {
      if (!this.items || this.items.length < LIMIT) return false;
      return true;
    },
    detailStyleObj() {
      const obj = {};
      if (this.detailWidth) obj.width = this.detailWidth;
      return obj;
    },
  },
  data() {
    return {
      selectedItems: [],
      filterOptions: { ...DEFAULT_FILTER_OPTIONS },
      limit: LIMIT,
      totalCount: 0,
      newItemVisible: false,
    };
  },
  mounted() {
    if (this.$route.params.listStatus) [this.filterOptions.listStatus] = this.$route.params.listStatus;
    if (this.$route.query) this.initItems();
  },
  methods: {
    fetchItems(isInit) {
      const { q, listStatus: status, offset, from: f, to: t } = this.filterOptions;
      const from = f ? dayjs(f).toISOString() : '';
      const to = t ? dayjs(t).toISOString() : '';
      const params = { limit: this.limit, offset, status, from, to, q };
      this.$emit('fetchItems', { isInit, params });
      this.$nextTick(() => {
        this.$refs.listTable.$el.scrollTop = 0;
      });
    },
    goToDetail(item) {
      this.selectedItems = [item];
    },
    toggleSelectedItem(item) {
      const duplicatedIndex = this.selectedItems.findIndex((o) => o._id === item._id);
      if (duplicatedIndex !== -1) {
        this.selectedItems.splice(duplicatedIndex, 1);
      } else {
        this.selectedItems.push(item);
      }
    },
    toggleAllSelectedItems() {
      if (this.isAllSelected) this.selectedItems = [];
      else this.selectedItems = [...this.items];
    },
    clearFilter() {
      this.filterOptions = { ...DEFAULT_FILTER_OPTIONS };
      this.fetchItems(true);
    },
    changeQueryFilter({ q, listStatus, from, to, offset: stringOffset }) {
      const offset = Number(stringOffset);
      const changedFilterOptions = { ...this.filterOptions };
      if (q) changedFilterOptions.q = q;
      if (listStatus) changedFilterOptions.listStatus = listStatus;
      if (from) changedFilterOptions.from = from;
      if (to) changedFilterOptions.to = to;
      if (!_.isNaN(offset)) changedFilterOptions.offset = offset;
      this.filterOptions = changedFilterOptions;
      this.fetchItems(true);
    },
    submitFilter(params) {
      this.filterOptions.offset = 0;
      this.changeQueryFilter(params);
    },
    initItems() {
      this.changeQueryFilter({ ...this.$route.query });
      this.selectedItems = [];
    },
    addNewItem() {
      this.initItems();
      this.newItemVisible = true;
    },
    updateList() {
      this.fetchItems(true);
    },
    clearSelected() {
      this.selectedItems = [];
    },
  },
};
</script>
