<style lang="less" scoped>

</style>

<template lang="pug">
order-sheet-template(
  v-for="orderSheet in orderSheets",
  :orderSheet="orderSheet",
  :key="orderSheet._id",
)
</template>

<script>
import _ from 'lodash';
import OrderSheetTemplate from './components/OrderSheetTemplate';

export default {
  components: {
    OrderSheetTemplate,
  },
  data() {
    return {
      orderSheets: [],
    };
  },
  async mounted() {
    await this.fetchOrderSheets();
    window.print();
  },
  methods: {
    async fetchOrderSheets() {
      try {
        const { oIds, q, listStatus: status, limit, offset, from, to } = this.$route.query;
        const params = { limit, offset, status, from, to, q };
        if (_.isArray(oIds)) params.ids = oIds.join(',');
        else if (_.isString(oIds)) params.ids = oIds;
        const { data: orderSheets } = await this.$http.get('/crew-only/order-sheets', { params });
        orderSheets.forEach((orderSheet) => this.orderSheets.push(orderSheet));
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
