import { createStore } from 'vuex';

export default function (http) {
  const store = createStore({
    state: {
      me: null,
    },
    mutations: {
      SET_ME(state, me) {
        state.me = me;
      },
    },
    actions: {
      async login({ dispatch }, { username, password }) {
        const { data } = await http.post('/login', { username, password });
        const { loginToken } = data;
        window.localStorage.setItem('loginToken', loginToken);
        await dispatch('fetchMe');
      },
      async fetchMe({ commit, state }) {
        const token = window.localStorage.getItem('loginToken');
        if (!token) return;
        if (state.me) return;
        try {
          await http.get('/crew-only/me');
          commit('SET_ME', true);
        } catch (e) {
          window.localStorage.removeItem('loginToken');
          throw e;
        }
      },
      async upload(_, { $event, apiType }) {
        try {
          const { files } = $event.target;
          if (!files || files.length === 0) return null;
          const file = files[0];
          const mimeType = file.type;
          let result;
          if (apiType === 'item') {
            const { data } = await http.post('/crew-only/public-files/upload', file, { headers: { 'content-type': mimeType } });
            result = data;
          } else if (apiType === 'review') {
            const { data } = await http.post('/crew-only/user-files/upload', file, { headers: { 'content-type': mimeType } });
            result = data;
          }
          return result;
        } catch (error) {
          console.log('실패');
          throw error;
        }
      },
    },
  });

  return store;
}
