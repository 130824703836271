<style lang="less" scoped>
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 20px;
}
.close-button {
  position: absolute;
  top: 21px;
  left: 20px;
  width: 18px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(~@/assets/close.svg);
}
.title {
  font-size: 18px;
  line-height: 160%;
}
.radio-button-group-wrapper {
  width: 280px;
}
.contents {
  padding: 20px;
}
.description {
  line-height: 200%;
  color: var(--black-4);
}
.button-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.button {
  .button();
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.progress-description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 200%;
  color: var(--orange-1);
}
</style>

<template lang="pug">
st-dialog(
  v-model="modelValue",
  @update:modelValue="$emit('update:modelValue', $event)",
)
  header.header
    button.close-button(@click="close")
    h1.title 주문 대량 추가
  section.contents
    p.description.
      아래에 샘플 엑셀 파일을 받아서 주문 정보들을 입력해주세요.#[br]
      샘플 엑셀 파일에서 작성된 날짜 포맷을 지켜서 입력해주시기 바랍니다.#[br]
      주문 상품칸은 싹뚝에서 정한 상품 데이터 아이디와 수량을 띄어쓰기로 구분해서 입력해주세요.#[br]
      수량은 1개, 2개, 3개 이런 형식으로 입력해주세요.#[br]
      여러 상품을 입력하는 경우에는 상품간에 콤마(,)로 구분해서 입력해주세요.#[br]
      예) productId1 1개, productId2 3개, productId3 2개
    .button-group
      a.button(href="/대량 주문 입력 샘플.xlsx", download) 샘플 다운로드
      file-input.button(@upload="upload") 엑셀 업로드
    p.progress-description(v-if="uploadTotalCount") {{ uploadTotalCount }} 중 {{ uploadCurrentCount }}개 처리 중
</template>

<script>
/* eslint-disable no-restricted-syntax, no-await-in-loop */
import _ from 'lodash';
import ImportExcel from '@/helpers/import-excel';
import StDialog from '@/components/StDialog';
import FileInput from '@/components/FileInput';

const excelFormBuilder = (row, products) => {
  const rowItems = row['주문 상품'];
  const formattedItems = rowItems.split(',')
    .map((s) => s.match(/(?<productId>\S+) (?<count>\d+)개/))
    .map((o) => {
      if (!o || !o.groups) return 'error';
      return o.groups;
    });
  const formattedItemValidity = !formattedItems.find((i) => i === 'error');
  if (!formattedItemValidity) return { isError: true, message: '주문 상품에 형식이 잘못되었습니다.' };

  const items = formattedItems.map((formattedItem, index) => {
    const { productId, count } = formattedItem;
    if (!_.isNumber(Number(count))) return { errorMessage: `주문 상품에 ${index + 1}번째 상품 숫자가 잘못되었습니다.` };
    const targetProduct = products.find((p) => p._id === productId);
    if (!targetProduct) return { errorMessage: `주문 상품에 ${index + 1}번째 상품 아이디가 잘못되었습니다.` };
    const { _id, name, price } = targetProduct;

    return {
      _id,
      name,
      unitPrice: price,
      quantity: count,
      subTotal: Number(price) * Number(count),
    };
  });
  const errorItem = !!items.find((i) => !!i.errorMessage);
  const itemValidity = !errorItem;
  if (!itemValidity) return { isError: true, message: errorItem.errorMessage };

  return {
    ordererPhoneNumber: row['주문한 사람 연락처'],
    ordererName: row['주문한 사람 이름'],
    phoneNumber: row['받는 사람 연락처'],
    name: row['받는 사람 이름'],
    zipcode: row['우편번호'],
    roadAddress: row['도로명 주소'],
    jibunAddress: row['지번 주소'],
    detailAddress: row['상세 주소'],
    manufacturingDate: row['생산(예정)일'],
    deliveryDue: row['도착(예정)일'],
    items,
    memo: row['메모'],
  };
};

export default {
  components: {
    StDialog,
    FileInput,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Boolean, default: false },
    products: { type: Array, default: () => [] },
  },
  data() {
    return {
      uploadTotalCount: 0,
      uploadCurrentCount: 0,
      detailAddress: '',
    };
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    },
    async upload(e) {
      const [file] = e.target.files;
      if (!file) return;
      const rows = await ImportExcel(file);
      const payload = rows.map((row) => excelFormBuilder(row, this.products));
      try {
        await this.$http.post('/crew-only/orders/bulk', payload);
        this.$message({ message: '주문이 전부 등록되었습니다.' });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
