<style lang="less" scoped>
.st-select-wrapper {
  position: relative;
  width: 100%;
  height: 32px;
}
.st-select {
  .input();
  height: 100%;
  padding: 0 10px 0 5px;
  line-height: 32px;
  font-size: 14px;
  appearance: none;
}
.indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 12px;
  height: 12px;
  background-image: url(~@/assets/chevron-down.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>

<template lang="pug">
.st-select-wrapper
  select.st-select(
    :value="modelValue",
    @input="$emit('update:modelValue', $event.target.value)",
    :readonly="readonly",
    :disabled="disabled",
  )
    slot
  i.indicator
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: String },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>
