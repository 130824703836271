<style lang="less" scoped>
.container {
  width: 21cm;
  height: 29.7cm;
  padding: 40px 0;
  margin: 60px auto;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}
.header {
  padding: 0 38px 0;
  margin-bottom: 30px;
}
.section {
  padding: 0 40px;
}
.contents {
  display: flex;
  margin-bottom: 15px;
}
.section-contents-frame {
  width: 100%;
}
.sub-title {
  width: 100%;
  letter-spacing: -0.1em;
  line-height: 160%;
  font-size: 10px;
  font-weight: 700;
  border-bottom: 2px solid #353535;
}
.manufacturing {
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.expiration {
  font-size: 15px;
  line-height: 200%;
  font-weight: 700;
  color: #353535;
  text-decoration: underline;
}
.table {
  width: 100%;
  border-bottom: 1px solid #353535;
}
.thead {
  width: 100%;
}
.thead-tr {
  width: 100%;
  display: flex;
}
.product-name {
  text-align: left;
  width: 43%;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.product-data {
  text-align: right;
  width: 19%;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.tbody {
  width: 100%;
}
.tbody-tr {
  width: 100%;
  display: flex;
}
.product-name-title {
  width: 43%;
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.product-data-title {
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  width: 19%;
  text-align: right;
  color: #353535;
}
.prices {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 30px;
}
.price {
  display: flex;
  text-align: right;
  width: 38%;
}
.price-text {
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
  font-weight: 700;
  width: 50%;
}
.price-cost {
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
  font-weight: 700;
  width: 50%;
}
.sub-descript {
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
  margin-bottom: 15px;
}
.frame-size-1 {
  width: 28.5%;
}
.frame-size-2 {
  width: 16%;
}
.frame-size-3 {
  width: 15%;
}
.frame-size-4 {
  width: 9.5%;
}
.contents-descript {
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.raw-material-name {
  margin-bottom: 15px;
}
.sub-material-descript {
  font-size: 10px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.accent {
  font-weight: 700;
  text-decoration: underline #353535;
}
.second-contents {
  border-bottom: 2px solid #353535;
  display: flex;
}
.component {
  width: 50%;
  letter-spacing: -0.1em;
  line-height: 160%;
  font-size: 12px;
  font-weight: 700;
}
.number {
  width: 50%;
  letter-spacing: -0.1em;
  line-height: 160%;
  font-size: 12px;
  font-weight: 700;
}
.second-table {
  width: 100%;
}
.second-tbody {
  width: 100%;
}
.second-tbody-tr {
  border-bottom: 1px solid #353535;
  width: 100%;
  display: flex;
}
.second-product-name-title {
  width: 50%;
  font-size: 12px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.second-product-data-title {
  width: 50%;
  font-size: 12px;
  letter-spacing: -0.1em;
  line-height: 160%;
  color: #353535;
}
.second-last-contents {
  border-top: 1px solid #353535;
  display: flex;
}
@media print {
  @page {
    size: A4;
    margin: 0;
    padding: 0;
  }
  .container {
    margin: 0;
    box-shadow: none;
  }
}
</style>

<template lang="pug">
.container
  header.header
    first-header(:orderSheet="orderSheet")
  section.section
    .contents
      .section-contents-frame
        p.sub-title 제조연월일
        p.manufacturing {{ orderSheet.manufacturingDate }}
      .section-contents-frame
        p.sub-title 유통기한
        p.expiration {{ orderSheet.bestBefore }}까지(냉장보관)
    .section-contents-frame
      p.sub-title 주문상품
      .table
        .thead
          .thead-tr
            p.product-name 상품명
            p.product-data 단가
            p.product-data 수량
            p.product-data 금액
        .tbody
          .tbody-tr(
            v-for="item in orderSheet.orderItems",
            :key="item._id",
          )
            p.product-name-title {{ item.name }}
            p.product-data-title ₩ {{ displayPrice(item.unitPrice) }}
            p.product-data-title {{ item.quantity }}
            p.product-data-title ₩ {{ displayPrice(item.subTotal) }}
      .prices
        .price(v-if="orderSheet.discount")
          p.price-text 할인
          p.price-cost -₩ {{ displayPrice(orderSheet.discount) }}
        .price
          p.price-text 배송비
          p.price-cost ₩ 0
        .price
          p.price-text 결제 금액
          p.price-cost ₩ {{ displayPrice(orderSheet.grandTotal) }}
    .product-name-info
      p.sub-title 제품명
      p.sub-descript {{ orderSheet.productName }}
    .contents
      .frame-size-1
        p.sub-title 영업소의 명칭 및 소재지
        p.contents-descript 싹뚝 / 서울 강남구 역삼로 137 3층
      .frame-size-2
        p.sub-title 용기(포장) 재질
        p.contents-descript PP
      .frame-size-2
        p.sub-title 보관방법
        p.contents-descript 냉장보관(0 ~ 10℃)
      .frame-size-3
        p.sub-title 식품유형
        p.contents-descript 간편 조리 세트
      .frame-size-4
        p.sub-title 내용량
        p.contents-descript {{ orderSheet.weight }}
      .frame-size-2
        p.sub-title 반품 및 교환
        p.contents-descript saktuk.com 문의
    .raw-material-name
      p.sub-title 원재료명
      p.sub-material-descript {{ orderSheet.ingredients }}
    .notice
      p.sub-title 유의사항
      p.sub-descript {{ orderSheet.warning }}
.container
  .header
    second-header(:orderSheet="orderSheet")
  .section
    .section-contents-frame
      .second-contents
        p.component 구성 요소 이름
        p.number 갯수
      .second-table
        .second-tbody
          .second-tbody-tr(
            v-for="component in orderSheet.components",
            :key="component._id",
          )
            p.second-product-name-title {{ component.name }}
            p.second-product-data-title {{ component.quantity }}
      .second-last-contents
        p.component 합계
        p.number {{ componentsTotalCount }}
</template>

<script>
import FirstHeader from './FirstHeader';
import SecondHeader from './SecondHeader';

export default {
  components: {
    FirstHeader,
    SecondHeader,
  },
  props: {
    orderSheet: { type: Object, default: null },
  },
  computed: {
    componentsTotalCount() {
      if (!this.orderSheet || !this.orderSheet.components) return 0;
      return this.orderSheet.components.reduce((acc, c) => acc + c.quantity, 0);
    },
  },
  methods: {
    displayPrice(price) {
      if (!price) return 0;
      return Number(price).toLocaleString();
    },
  },
};
</script>
