<style lang="less" scoped>
.form-contents {
  flex: 1;
  padding: 10px;
  overflow: auto;
}
.link-img {
  position: absolute;
  top: 2px;
  right: 0;
  background-color: var(--primary-6);
  border-radius: 6px;
  width: 20px;
  height: 20px;
  background-image: url(~@/assets/link-img.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  display: block;
}
.upload-button {
  .button();
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.input {
  .input();
}
.draggable {
  width: 100%;
}
.description-image-item-wrapper {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.footer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
  z-index: 2;
}
.remove-button {
  .button(@color: var(--orange-1));
  margin-right: auto;
}
.common {
  .button();
  margin-right: 10px;
}
.caution-text {
  font-size: 12px;
  color: #ff1d45;
  line-height: 130%;
}
</style>

<template lang="pug">
.form-contents
  .form-group
    a.link-img(:href="`https://saktuk.com/items/${localItem._id}`" target="_black")
    label.label 아이템 ID
    input.input(v-model="localItem._id", readonly)
  .form-group
    a.link-img(:href="`https://saktuk.com/items/${localItem.url}`" target="_black")
    label.label 아이템 URL
    input.input(v-model="localItem.url")
  .form-group
    label.label 이름
    input.input(v-model="localItem.name")
  .form-group
    label.label 요약
    input.input(v-model="localItem.summary")
  .form-group
    label.label 연결 상품명 선택
    st-select(v-model="localItem.productId")
      option(
        v-for="product in products",
        :key="product._id",
        :value="product._id",
      ) {{ product.name }}
  .form-group
    label.label 메인 이미지
    thumbnail-uploader(
      id="coverImage",
      :image="localItem.coverImage",
      :editable="true",
      @upload="uploadCoverImage",
    )
  .form-group
    label.label 설명 이미지
    draggable.draggable(v-model="localItem.descriptions", item-key="id")
      template(#item="{ element, index }")
        .description-image-item-wrapper
          description-image-item(
            :image="element.image",
            v-model:text="element.text",
            @upload="uploadDescriptionImage(index, $event)",
            @remove="removeDescriptionImage(index)",
          )
    button.upload-button(id="descriptionImageUploader", @click="addDescriptionImage") 새로 만들기
  .form-group
    label.label 유의사항
    st-textarea(:maxHeight="400", v-model="localItem.notice")
  .form-group
    label.label OG 제목
    input.input(v-model="localItem.ogTitle")
  .form-group
    label.label OG 설명
    input.input(v-model="localItem.ogDescription")
  .form-group
    label.label OG 이미지
    thumbnail-uploader(
      id="ogImage",
      :editable="true",
      :image="localItem.ogImage",
      @upload="uploadOgImage",
    )
    p.caution-text(v-if="localItem.ogImage === ''") og이미지가 없을시 메인 이미지가 들어갑니다.
  .form-group
    label.label 검색 태그
    st-textarea(
      placeholder="최대 100자까지 들어 갈 수 있으며 |로 구분해주세요. (10개까지 입력 가능)",
      v-model="localItem.tag",
      @blur="blurTag",
    )
footer.footer
  button.remove-button(@click="$emit('remove')") 삭제하기
  button.duplicate-button.common(@click="duplicate") 복제하기
  button.save-button.common(@click="$emit('submit', localItem)") 저장하기
</template>

<script>
import draggable from 'vuedraggable';

import ThumbnailUploader from '@/components/ThumbnailUploader';
import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';
import DescriptionImageItem from './DescriptionImageItem';

const DEFAULT_ITEM = {
  name: '',
  productId: '',
  coverImage: '',
  descriptions: [],
  notice: '',
  ogTitle: '',
  ogDescription: '',
  ogImage: '',
  tag: '',
};

export default {
  components: {
    draggable,
    ThumbnailUploader,
    StTextarea,
    StSelect,
    DescriptionImageItem,
  },
  emits: ['remove', 'submit'],
  props: {
    isNew: { type: Boolean, default: false },
    item: { type: Object, default: null },
    products: { type: Array, default: () => [] },
  },
  watch: {
    item(v) {
      if (v) this.initData();
    },
  },
  data() {
    return {
      localItem: DEFAULT_ITEM,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.item) this.localItem = { ...this.item };
    },
    async duplicate() {
      try {
        await this.$http.post(`/crew-only/items/${this.localItem._id}/copy`);
        this.$message({ message: '선택한 아이템 복제 되었습니다.' });
        this.$router.go();
      } catch (error) {
        console.log(error);
      }
    },
    async upload($event) {
      try {
        const apiType = 'item';
        const data = await this.$store.dispatch('upload', { $event, apiType });
        const imageUrl = data.url;
        this.$message({ message: '이미지가 업로드 되었습니다' });
        return imageUrl;
      } catch (error) {
        return this.$message({ message: '이미지가 업로드 되지 못했습니다. 이미지 크기나 이미지 타입을 확인 해주세요' });
      }
    },
    async uploadCoverImage($event) {
      try {
        const imageUrl = await this.upload($event);
        this.localItem.coverImage = imageUrl;
      } catch (e) {
        console.error(e);
      }
    },
    async uploadOgImage($event) {
      try {
        const imageUrl = await this.upload($event);
        this.localItem.ogImage = imageUrl;
      } catch (e) {
        console.error(e);
      }
    },
    async uploadDescriptionImage(index, $event) {
      try {
        const imageUrl = await this.upload($event);
        this.localItem.descriptions[index].image = imageUrl;
      } catch (e) {
        console.error(e);
      }
    },
    removeDescriptionImage(index) {
      this.localItem.descriptions.splice(index, 1);
    },
    addDescriptionImage() {
      this.localItem.descriptions.push({ image: '', text: '' });
    },
    blurTag() {
      if ((this.localItem.tag || '').trim().split('|').length > 10) {
        this.localItem.tag = this.localItem.tag.split('|').slice(0, 10);
        this.localItem.tag = this.localItem.tag.join('|');
        alert('검색태그는 최대 10개 까지 입력하실 수 있습니다.');
        return;
      }
      if ((this.localItem.tag || '').length > 100) {
        this.localItem.tag = this.localItem.tag.slice(0, 100);
        alert('검색태그는 최대 100자 까지 입력하실 수 있습니다.');
      }
    },
  },
};
</script>
