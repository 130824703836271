import XLSX from 'xlsx';

const importExcel = (file) => (
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const targetSheetName = wb.SheetNames[0];
      const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[targetSheetName]);
      resolve(rowObj);
    };
    reader.readAsBinaryString(file);
  })
);

export default importExcel;
