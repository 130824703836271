<style lang="less" scoped>
.radio-button-group {
  display: flex;
  width: 100%;
  border-radius: 8px;
  background-color: var(--primary-6);
}
.radio-button {
  width: 50%;
  height: 28px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  line-height: 28px;
  color: rgba(73, 101, 84, .8);
  &.active {
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(77, 117, 87, 0.2);
    background-color: var(--primary-2);
    color: var(--primary-7);
  }
}
</style>

<template lang="pug">
.radio-button-group
  button.radio-button(
    v-for="option in options",
    :key="options.value",
    :class="{ active: modelValue === option.value }",
    @click="$emit('update:modelValue', option.value)",
  ) {{ option.label }}
</template>

<script>
const OPTIONS_KEYS = ['label', 'value'];

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: String, default: '' },
    options: {
      type: Array,
      default: () => [],
      validator: (options) => (options.findIndex((option) => Object.keys(option) !== OPTIONS_KEYS) !== -1),
    },
  },
};
</script>
