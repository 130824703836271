<style lang="less" scoped>
.new-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 10px;
}
</style>

<template lang="pug">
.new-item-container
  form-contents(
    :isNew="true",
    :products="products",
    @submit="submitItem",
  )
</template>

<script>
import FormContents from './FormContents';

export default {
  components: {
    FormContents,
  },
  emits: ['refresh'],
  props: {
    products: { type: Object, default: null },
  },
  methods: {
    async submitItem(item) {
      try {
        await this.$http.post('/crew-only/items', item);
        this.$message({ message: '아이템이 추가되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
  },
};
</script>
