<style lang="less" scoped>
.new-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 10px;
}
</style>

<template lang="pug">
.new-item-container
  form-contents(@submit="submitShortLink")
</template>

<script>
import FormContents from './FormContents';

export default {
  components: {
    FormContents,
  },
  emits: ['refresh'],
  methods: {
    async submitShortLink(shortLink) {
      try {
        await this.$http.post('/crew-only/short-links', shortLink);
        this.$message({ message: '단축 URL이 추가되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
  },
};
</script>
