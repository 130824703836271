<style lang="less" scoped>
.contents {
  flex: 1;
  padding: 0 10px;
  overflow: auto;
}
.input {
  .input();
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
}
.remove-button {
  .button(@color: var(--orange-1));
}
.save-button {
  .button();
}
</style>

<template lang="pug">
.contents
  template(v-if="isMultiple")
    .form-group
      label.label 선택된 쿠폰 갯수
      input.input(:value="selectedItems.length", readonly)
    .form-group
      label.label 활성화
      st-select(v-model="multipleActivated")
        option(:value="true") true
        option(:value="false") false
  template(v-else-if="couponCode")
    .form-group
      label.label 코드
      input.input(v-model="couponCode.code")
    .form-group
      label.label 이름
      input.input(v-model="couponCode.name")
    .form-group
      label.label 할인 금액
      input.input(v-model="couponCode.discountAmount" type="number")
    .form-group
      label.label 코드 만료 기간
      input.input(v-model="couponCode.codeValidUntil", type="date")
    .form-group
      label.label 쿠폰 만료 기간
      input.input(v-model="couponCode.couponValidUntil", type="date" )
    .form-group
      label.label 발급 후 n일 뒤 만료
      input.input(v-model="couponCode.expiresAfterIssuance" type="number")
    .form-group
      label.label 쿠폰 코드 타입
      st-select(v-model="couponCode.type")
        option(value="once") 쿠폰 1개
        option(value="once-per-user") 사용자당 1개
footer.footer
  button.remove-button(v-if="!isMultiple", @click="removeCouponCode") 삭제하기
  button.save-button(@click="submit") 저장하기
</template>

<script>
import dayjs from 'dayjs';
import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';

dayjs.locale('ko');

export default {
  components: {
    StTextarea,
    StSelect,
  },
  emits: ['refresh', 'clearSelected'],
  props: {
    selectedItems: { type: Array, default: () => [] },
  },
  watch: {
    isMultiple(value) {
      if (!value) this.fetchCouponCode();
    },
    selectedItemId() {
      this.fetchCouponCode();
    },
  },
  computed: {
    isMultiple() {
      if (!this.selectedItems) return false;
      if (this.selectedItems.length === 1) return false;
      return true;
    },
    selectedItemId() {
      if (this.isMultiple) return null;
      return this.selectedItems[0]._id;
    },
  },
  data() {
    return {
      couponCode: null,
      multipleActivated: '',
    };
  },
  mounted() {
    if (!this.isMultiple) this.fetchCouponCode();
  },
  methods: {
    async fetchCouponCode() {
      if (!this.selectedItems[0]) return;
      try {
        const couponCodeId = this.selectedItems[0]._id;
        const { data: couponCode } = await this.$http.get(`/crew-only/coupon-codes/${couponCodeId}`);
        this.couponCode = couponCode;
        couponCode.codeValidUntil = this.displayDate(couponCode.codeValidUntil);
        couponCode.couponValidUntil = this.displayDate(couponCode.couponValidUntil);
      } catch (e) {
        console.error(e);
      }
    },
    displayDate(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    async removeCouponCode() {
      if (!window.confirm('쿠폰을 삭제하시겠습니까?')) return;
      try {
        await this.$http.delete(`/crew-only/coupon-codes/${this.couponCode._id}`);
        this.$emit('clearSelected');
        this.$message({ message: '쿠폰이 삭제되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    submit() {
      if (this.isMultiple) this.updateMultipleCouponCodes();
      else this.updateCouponCode();
    },
    async updateMultipleCouponCodes() {
      try {
        const payload = { activated: this.multipleActivated };
        const couponCodeIds = this.selectedItems.map((item) => item._id).join(',');
        await this.$http.put('/crew-only/coupon-codes', payload, { params: { ids: couponCodeIds } });
        this.$message({ message: '선택된 쿠폰들이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    async updateCouponCode() {
      try {
        await this.$http.put(`/crew-only/coupon-codes/${this.couponCode._id}`, this.couponCode);
        this.$message({ message: '쿠폰이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
  },
};
</script>
