<template lang="pug">
list-layout(
  detailWidth="400px",
  :hasFilter="false",
  :hasNewButton="true",
  :loading="loading",
  :items="items",
  @fetchItems="fetchItems",
)
  template(v-slot:table-columns)
    list-table-column(label="이름", prop="name")
    list-table-column(label="연결 상품명", prop="productId")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems, clearSelected }")
    selected-contents(
      :selectedItems="selectedItems",
      :products="products",
      @refresh="fetchItems({ isInit: true })",
      @clearSelected="clearSelected",
    )
  template(v-slot:new-contents)
    new-item-contents(
      :products="products",
      @refresh="fetchItems({ isInit: true })",
    )
</template>

<script>
import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';
import NewItemContents from './components/NewItemContents';

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
    NewItemContents,
  },
  data() {
    return {
      loading: false,
      items: [],
      products: [],
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchItems({ isInit, params }) {
      try {
        this.loading = true;
        const { data: items } = await this.$http.get('/crew-only/items', { params });
        if (isInit) this.items = items;
        else items.forEach((item) => this.items.push(item));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchProducts() {
      const { data: products } = await this.$http.get('/products');
      this.products = products;
    },
  },
};
</script>
