<style lang="less" scoped>
.header-container {
  width: 100%;
}
.header-img {
  display: flex;
  justify-content: right;
}
.logo-img {
  width: 30px;
  height: 30px;
}
.header-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}
.header-descript {
  font-size: 10px;
  line-height: 160%;
  color: #353535;
}
</style>

<template lang="pug">
.header-container
  .header-img
    img.logo-img(src="@/assets/minilogo.svg")
  h1.header-title 이 상자에 포함되는 구성 요소, 주문: {{ orderSheet.orderId }}
  p.header-descript 담당 쌈쌈이님, 빠짐없도록 다시 한번 체크 부탁드려요.
</template>

<script>
export default {
  props: {
    orderSheet: { type: Object, default: null },
  },

};
</script>
