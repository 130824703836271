<style lang="less" scoped>
.form-contents {
  flex: 1;
  padding: 10px;
  overflow: auto;
}
.link-img {
  position: absolute;
  top: 2px;
  right: 0;
  background-color: var(--primary-6);
  border-radius: 6px;
  width: 20px;
  height: 20px;
  background-image: url(~@/assets/link-img.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  display: block;
}
.upload-button {
  .button();
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.input {
  .input();
}
.draggable {
  width: 100%;
}
.description-image-item-wrapper {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.footer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
  z-index: 2;
}
.remove-button {
  .button(@color: var(--orange-1));
  margin-right: auto;
}
.common {
  .button();
  margin-right: 10px;
}
</style>

<template lang="pug">
.form-contents
  .form-group
    a.link-img(:href="`https://saktuk.com/item-lists/${localItem._id}`" target="_black")
    label.label 아이템 ID
    input.input(v-model="localItem._id")
  .form-group
    a.link-img(:href="`https://saktuk.com/item-lists/${localItem.url}`" target="_black")
    label.label 아이템 리스트 URL
    input.input(v-model="localItem.url")
  .form-group
    label.label 제목
    input.input(v-model="localItem.title")
  .form-group
    label.label 요약
    input.input(v-model="localItem.summary")
  .form-group
    label.label 설명 이미지
    draggable.draggable(v-model="localItem.descriptions", item-key="id")
      template(#item="{ element, index, column }")
        .description-image-item-wrapper
          description-image-item(
            :image="element.image",
            :index="index",
            v-model:column="element.column",
            v-model:text="element.text",
            v-model:redirectUrl="element.redirectUrl",
            @upload="uploadDescriptionImage(index, $event)",
            @remove="removeDescriptionImage(index)",
          )
    button.upload-button(id="descriptionImageUploader", @click="addDescriptionImage") 추가하기
  .form-group
    label.label 아이템
    st-textarea(v-model="localItem.itemIds")
  .form-group
    label.label OG 이미지
    thumbnail-uploader(
      id="ogImage",
      :editable="true",
      :image="localItem.ogImage",
      @upload="uploadOgImage",
    )
  .form-group
    label.label OG 제목
    input.input(v-model="localItem.ogTitle")
  .form-group
    label.label OG 설명
    input.input(v-model="localItem.ogDescription")
footer.footer
  button.remove-button(@click="$emit('remove')") 삭제하기
  button.duplicate-button.common(@click="duplicate") 복제하기
  button.save-button.common(@click="submit") 저장하기
</template>

<script>
import draggable from 'vuedraggable';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

import ThumbnailUploader from '@/components/ThumbnailUploader';
import StTextarea from '@/components/StTextarea';
import DescriptionImageItem from './DescriptionImageItem';

const DEFAULT_ITEM = {
  _id: '',
  title: '',
  summary: '',
  descriptions: [],
  itemIds: '',
  ogImage: '',
  ogTitle: '',
  ogDescription: '',
};

export default {
  components: {
    draggable,
    ThumbnailUploader,
    StTextarea,
    DescriptionImageItem,
  },
  emits: ['remove', 'submit'],
  props: {
    item: { type: Object, default: null },
  },
  watch: {
    item(v) {
      if (v) this.initData();
    },
  },
  data() {
    return {
      localItem: DEFAULT_ITEM,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.item) {
        this.localItem = { ...this.item };
        this.localItem.itemIds = this.localItem.itemIds.join('\n');
      }
    },
    submit() {
      const itemIds = this.localItem.itemIds.split('\n');
      this.$emit('submit', { ...this.localItem, itemIds });
    },
    async duplicate() {
      try {
        await this.$http.post(`/crew-only/item-lists/${this.localItem._id}/copy`);
        this.$message({ message: '선택한 아이템 리스트가 복제 되었습니다.' });
      } catch (error) {
        console.log(error);
      }
    },
    async upload($event) {
      try {
        const apiType = 'item';
        const data = await this.$store.dispatch('upload', { $event, apiType });
        const imageUrl = data.url;
        this.$message({ message: '이미지가 업로드 되었습니다' });
        return imageUrl;
      } catch (error) {
        return this.$message({ message: '이미지가 업로드 되지 못했습니다. 이미지 크기나 이미지 타입을 확인 해주세요' });
      }
    },
    async uploadOgImage($event) {
      try {
        const url = await this.upload($event);
        this.localItem.ogImage = url;
      } catch (e) {
        console.error(e);
      }
    },
    async uploadDescriptionImage(index, $event) {
      try {
        const url = await this.upload($event);
        this.localItem.descriptions[index].image = url;
      } catch (e) {
        console.error(e);
      }
    },
    removeDescriptionImage(index) {
      this.localItem.descriptions.splice(index, 1);
    },
    addDescriptionImage() {
      this.localItem.descriptions.push({ image: '', text: '', column: 2, redirectUrl: '' });
    },
  },
};
</script>
