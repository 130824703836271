<style lang="less" scoped>
.detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.contents {
  flex: 1;
  padding: 10px;
  overflow: auto;
}
.input {
  .input();
}
.order-product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  > * {
    flex-shrink: 0;
  }
}
.order-product-item-input {
  width: 100px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
}
.save-button {
  .button();
}
</style>

<template lang="pug">
.detail-container
  .contents
    .form-group
      label.label 주문한 사람 연락처
      input.input(v-model="ordererPhoneNumber", placeholder="주문한 사람 연락처")
    .form-group
      label.label 주문한 사람 이름
      input.input(v-model="ordererName", placeholder="주문한 사람 이름")
    .form-group
      label.label 받는 사람 연락처
      input.input(v-model="phoneNumber", placeholder="받는 사람 연락처")
    .form-group
      label.label 받는 사람 이름
      input.input(v-model="name", placeholder="받는 사람 이름")
    .form-group
      label.label 우편번호
      input.input(v-model="zipcode", placeholder="우편번호")
    .form-group
      label.label 도로명 주소
      input.input(v-model="roadAddress", placeholder="도로명 주소")
    .form-group
      label.label 지번 주소
      input.input(v-model="jibunAddress", placeholder="지번 주소")
    .form-group
      label.label 상세 주소
      input.input(v-model="detailAddress", placeholder="상세 주소")
    .form-group
      label.label 생산(예정)일
      input.input(v-model="manufacturingDate", type="date", placeholder="생산(예정)일")
    .form-group
      label.label 도착(예정)일
      input.input(v-model="deliveryDue", type="date", placeholder="도착(예정)일")
    .form-group
      label.label 주문 상품
      .order-product-item(
        v-for="localProduct in localProducts",
        :key="localProduct._id",
      )
        p.order-product-item-label {{ localProduct._id }}
        input.input.order-product-item-input(
          placeholder="수량",
          type="number",
          min="0",
          v-model="localProduct.quantity",
        )
    .form-group
      label.label 메모
      input.input(v-model="memo", placeholder="메모")
  footer.footer
    button.save-button(@click="submit") 저장하기
</template>

<script>
import dayjs from 'dayjs';

dayjs.locale('ko');

export default {
  emits: ['refresh'],
  props: {
    products: { type: Array, default: () => [] },
  },
  watch: {
    products() {
      this.initLocalProducts();
    },
  },
  data() {
    return {
      ordererPhoneNumber: '',
      ordererName: '',
      phoneNumber: '',
      name: '',
      zipcode: '',
      roadAddress: '',
      jibunAddress: '',
      detailAddress: '',
      manufacturingDate: '',
      deliveryDue: '',
      localProducts: [],
      memo: '',
    };
  },
  mounted() {
    this.initLocalProducts();
  },
  methods: {
    initLocalProducts() {
      this.localProducts = [];
      this.products.forEach((p) => this.localProducts.push({ ...p, quantity: 0 }));
    },
    async submit() {
      const items = this.localProducts.map((p) => ({
        _id: p._id,
        name: p.name,
        unitPrice: p.price,
        quantity: p.quantity,
        subTotal: Number(p.price) * Number(p.quantity),
      })).filter((p) => p.quantity > 0);
      try {
        await this.$http.post('/crew-only/orders', {
          ordererPhoneNumber: this.ordererPhoneNumber,
          ordererName: this.ordererName,
          phoneNumber: this.phoneNumber,
          name: this.name,
          zipcode: this.zipcode,
          roadAddress: this.roadAddress,
          jibunAddress: this.jibunAddress,
          detailAddress: this.detailAddress,
          manufacturingDate: this.manufacturingDate,
          deliveryDue: this.deliveryDue,
          items,
          memo: this.memo,
        });
        this.$message({ message: '주문이 등록되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
