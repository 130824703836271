<style lang="less" scoped>
.container {
  padding: 20px;
  height: 100%;
  overflow: hidden;
  background-color: #F8FBF9;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 0 10px;
  box-shadow: 0px 4px 32px rgba(77, 117, 87, 0.05);
  background-color: var(--white-1);
}
.logo-button {
  display: flex;
  padding: 14px 20px;
  cursor: pointer;
}
.nav-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-icon-button {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: var(--primary-6);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  &.refresh { background-image: url(~@/assets/refresh.svg); }
  &.logout { background-image: url(~@/assets/logout.svg); }
}
.divider {
  width: 1px;
  height: 32px;
  margin: 0 20px;
  background-color: var(--primary-5);
}
.nav-profile-button {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.article {
  flex: 1;
  display: flex;
  height: calc(100% - 80px);
}
.aside {
  width: 200px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 32px rgba(77, 117, 87, 0.05);
  border-radius: 12px;
  background-color: var(--white-1);
}
.section {
  flex: 1;
  display: flex;
  height: 100%;
  margin-left: 20px;
  overflow: hidden;
}
@media only screen and (min-width: 769px) {
  .main {
    height: 100%;
  }
}
</style>

<template lang="pug">
main.container
  nav.nav
    router-link.logo-button(to="/")
      img.logo(src="@/assets/logo.svg")
    .nav-right
      button.nav-icon-button.refresh
      hr.divider
      button.nav-icon-button.logout(@click="logout")
      button.nav-profile-button(:style="{ backgroundImage: `url(${require('@/assets/test-user.svg')})` }")
  article.article
    aside.aside
      category
    section.section
      router-view(:key="routeListStatus")
</template>
<script>
import Category from './components/Category';

export default {
  components: {
    Category,
  },
  computed: {
    routeListStatus() {
      if (!this.$route.params || !this.$route.params.listStatus) return null;
      const [listStatus] = this.$route.params.listStatus;
      return listStatus;
    },
  },
  methods: {
    logout() {
      // TODO: logout 처리
    },
  },
};
</script>
