<style lang="less" scoped>
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 20px;
}
.close-button {
  position: absolute;
  top: 21px;
  left: 20px;
  width: 18px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(~@/assets/close.svg);
}
.radio-button-group-wrapper {
  width: 280px;
}
.contents {
  padding: 20px;
}
.description {
  line-height: 200%;
  color: var(--black-4);
}
.button-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px -10px -5px -10px;
}
.button {
  .button();
  margin: 5px 10px;
}
</style>

<template lang="pug">
st-dialog(
  v-model="modelValue",
  @update:modelValue="$emit('update:modelValue', $event)",
)
  header.header
    button.close-button(@click="close")
    .radio-button-group-wrapper
      radio-button-group(v-model="type", :options="typeOptions")
  section.contents(v-if="type === 'productionPlan'")
    p.description 구성요소별 생산량을 통합하여 출력합니다.
    .button-group
      button.button(@click="$emit('printAllProductionPlanFromList')") 현재 목록 모두 인쇄하기
      button.button(@click="$emit('printAllProductionPlanFromSelected')") 선택된 항목 인쇄하기
  section.contents(v-else-if="type === 'orderSheet'")
    p.description 효율적인 관리를 위해 반드시 양면 인쇄를 설정해주세요.
    .button-group
      button.button(@click="$emit('printAllOrderSheetFromList')") 현재 목록 모두 인쇄하기
      button.button(@click="$emit('printAllOrderSheetFromSelected')") 선택된 항목 인쇄하기
</template>

<script>
import StDialog from '@/components/StDialog';
import RadioButtonGroup from '@/components/RadioButtonGroup';

export default {
  components: {
    StDialog,
    RadioButtonGroup,
  },
  emits: [
    'update:modelValue',
    'printAllProductionPlanFromList',
    'printAllProductionPlanFromSelected',
    'printAllOrderSheetFromList',
    'printAllOrderSheetFromSelected',
  ],
  props: {
    modelValue: { type: Boolean, default: false },
  },
  data() {
    return {
      type: 'productionPlan', // 'productionPlan', 'orderSheet'
      typeOptions: [
        { label: '생산 계획서', value: 'productionPlan' },
        { label: '개별 주문서', value: 'orderSheet' },
      ],
    };
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    },
  },
};
</script>
