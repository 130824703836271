<style lang="less" scoped>
.header-container {
  width: 100%;
}
.header-img {
  display: flex;
  justify-content: right;
}
.logo-img {
  width: 30px;
  height: 30px;
}
.header-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
}
.header-descript {
  font-size: 10px;
  line-height: 160%;
  color: #353535;
}
</style>

<template lang="pug">
.header-container
  .header-img
    img.logo-img(src="@/assets/minilogo.svg")
  h1.header-title 유통기한, 주문, 식품위생법에 의한 한글 표시사항 확인
  p.header-descript {{ displayOrderCreatedAt }} {{ orderSheet.orderName }}님 · {{ orderSheet.orderId }} · 주문해주셔서 감사해요 :)
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

dayjs.extend(localizedFormat);
dayjs.locale('ko');

export default {
  props: {
    orderSheet: { type: Object, default: null },
  },
  computed: {
    displayOrderCreatedAt() {
      if (!this.orderSheet) return '(알 수 없음)';
      return dayjs(this.orderSheet.orderSheet).format('YY년 M월 D일 a h시');
    },
  },
};
</script>
