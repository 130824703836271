<template lang="pug">
list-layout(
  :hasFilter="false",
  :hasNewButton="true",
  :loading="loading",
  :items="couponCodes",
  @fetchItems="fetchCouponCodes",
)
  template(v-slot:table-columns)
    list-table-column(label="이름", prop="name")
    list-table-column(label="코드", prop="code")
    list-table-column(label="코드 만료기간", prop="codeValidUntil", :filter="displayDate")
    list-table-column(label="할인 금액", prop="discountAmount")
    list-table-column(label="쿠폰 코드 타입", prop="type", :filter="displayType")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems, clearSelected }")
    selected-contents(
      :selectedItems="selectedItems",
      @refresh="fetchCouponCodes({ isInit: true })",
      @clearSelected="clearSelected",
    )
  template(v-slot:new-contents)
    new-item-contents(@refresh="fetchCouponCodes({ isInit: true })")
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';
import NewItemContents from './components/NewItemContents';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
    NewItemContents,
  },
  data() {
    return {
      loading: false,
      couponCodes: [],
    };
  },
  methods: {
    async fetchCouponCodes({ isInit, params }) {
      try {
        this.loading = true;
        const { data: couponCodes } = await this.$http.get('/crew-only/coupon-codes', { params });
        if (isInit) this.couponCodes = couponCodes;
        else couponCodes.forEach((couponCode) => this.couponCodes.push(couponCode));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    displayDate(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    displayType(type) {
      if (type === 'once') return '쿠폰 1개';
      if (type === 'once-per-user') return '사용자당 1개';
      return '없음';
    },
  },
};
</script>
