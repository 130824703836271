<style lang="less" scoped>

</style>

<style lang="less">
.st-custom-el-dialog {
  box-shadow: 0px 4px 32px rgba(77, 117, 87, 0.05);
  border-radius: 12px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    color: var(--black-2);
  }
}
</style>

<template lang="pug">
el-dialog(
  v-model="modelValue",
  :destroy-on-close="true",
  :append-to-body="true",
  @update:modelValue="$emit('update:modelValue', $event)",
  width="600px",
  custom-class="st-custom-el-dialog",
)
  slot
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Boolean, default: false },
  },
};
</script>
