<style lang="less" scoped>
.st-textarea {
  .input();
  display: block;
  overflow-y: auto;
  resize: none;
  font-size: 14px;
  line-height: 22px;
}
</style>

<template lang="pug">
textarea.st-textarea(
  :value="modelValue",
  :style="styleObject",
  @input="input",
  @focus="focus",
  @blur="blur",
  @paste="paste",
)
</template>

<script>
const BORDER_WIDTH = 1;

export default {
  props: {
    modelValue: { type: String, default: '' },
    minHeight: { type: Number, default: 54 },
    maxHeight: { type: Number, default: null },
  },
  watch: {
    modelValue: 'resize',
  },
  data() {
    return {
      height: 0,
    };
  },
  computed: {
    styleObject() {
      const object = {
        minHeight: `${this.minHeight}px`,
        height: `${this.height}px`,
      };
      if (this.maxHeight) {
        object.maxHeight = `${this.maxHeight}px`;
      }
      return object;
    },
  },
  mounted() {
    this.resize();
  },
  methods: {
    resize() {
      this.height = this.minHeight;
      this.$nextTick(() => {
        this.height = this.$el.scrollHeight + (BORDER_WIDTH * 2);
      });
    },
    input(e) {
      this.$el.style.height = `${this.minHeight}px`;
      this.$el.style.height = `${this.$el.scrollHeight + (BORDER_WIDTH * 2)}px`;
      this.$emit('update:modelValue', e.target.value);
    },
    focus(e) {
      this.$emit('focus', e);
    },
    blur(e) {
      this.$emit('blur', e);
    },
    paste(e) {
      this.$emit('paste', e);
      this.$nextTick(() => {
        this.resize();
      });
    },
  },
};
</script>
