<template lang="pug">
list-layout(
  :hasFilter="false",
  :loading="loading",
  :items="withdrawals",
  @fetchItems="fetchWithdrawals",
)
  template(v-slot:table-columns)
    list-table-column(label="요청일", prop="createdAt", :filter="displayDate")
    list-table-column(label="이름", prop="localSeller.name")
    list-table-column(label="연락처", prop="localSeller.username")
    list-table-column(label="처리 상태", prop="status", :filter="displayStatus")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems, clearSelected }")
    selected-contents(
      :selectedItems="selectedItems",
      @refresh="fetchWithdrawals({ isInit: true })",
      @clearSelected="clearSelected",
    )
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
  },
  data() {
    return {
      loading: false,
      withdrawals: [],
    };
  },
  methods: {
    async fetchWithdrawals({ isInit, params }) {
      try {
        this.loading = true;
        const { data: withdrawals } = await this.$http.get('/crew-only/local-seller-withdrawal-requests', { params });
        if (isInit) this.withdrawals = withdrawals;
        else withdrawals.forEach((withdrawal) => this.withdrawals.push(withdrawal));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    displayDate(date) {
      if (!date) return '';
      return dayjs(date).format('LLL');
    },
    displayStatus(status) {
      if (status === 'pending') return '대기중';
      if (status === 'processing') return '처리중';
      if (status === 'finished') return '완료';
      return '취소';
    },
  },
};
</script>
