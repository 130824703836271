<style lang="less" scoped>
.contents {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
</style>

<template lang="pug">
.contents
  template(v-if="isMultiple")
    .form-group
      label.label 선택된 아이템 리스트 갯수
      input.input(:value="selectedItems.length", readonly)
    .form-group
      label.label 활성화
      st-select(v-model="multipleActivated")
        option(:value="true") true
        option(:value="false") false
  template(v-else-if="itemList")
    form-contents(
      :item="itemList",
      @submit="submitItemList",
      @remove="removeItemList",
    )
</template>

<script>
import StSelect from '@/components/StSelect';
import FormContents from './FormContents';

export default {
  components: {
    StSelect,
    FormContents,
  },
  emits: ['refresh', 'clearSelected'],
  props: {
    selectedItems: { type: Array, default: () => [] },
  },
  watch: {
    isMultiple(value) {
      if (!value) this.fetchItemList();
    },
    selectedItemId() {
      this.fetchItemList();
    },
  },
  computed: {
    isMultiple() {
      if (!this.selectedItems) return false;
      if (this.selectedItems.length === 1) return false;
      return true;
    },
    selectedItemId() {
      if (this.isMultiple) return null;
      return this.selectedItems[0]._id;
    },
  },
  data() {
    return {
      itemList: null,
      multipleActivated: '',
    };
  },
  mounted() {
    if (!this.isMultiple) this.fetchItemList();
  },
  methods: {
    async fetchItemList() {
      if (!this.selectedItems[0]) return;
      try {
        const itemListId = this.selectedItems[0]._id;
        const { data: itemList } = await this.$http.get(`/crew-only/item-lists/${itemListId}`);
        this.itemList = itemList;
      } catch (e) {
        console.error(e);
      }
    },
    async removeItemList() {
      if (!window.confirm('아이템 리스트를 삭제하시겠습니까?')) return;
      try {
        await this.$http.delete(`/crew-only/item-lists/${this.itemList._id}`);
        this.$emit('clearSelected');
        this.$message({ message: '아이템 리스트가 삭제되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    submitItemList(changedItemList) {
      if (this.isMultiple) this.updateMultipleItemLists();
      else this.updateItemList(changedItemList);
    },
    async updateMultipleItemLists() {
      try {
        const payload = { activated: this.multipleActivated };
        const itemIds = this.selectedItems.map((item) => item._id).join(',');
        await this.$http.put('/crew-only/item-lists', payload, { params: { ids: itemIds } });
        this.$message({ message: '선택된 아이템 리스트들이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    async updateItemList(changedItemList) {
      try {
        await this.$http.put(`/crew-only/item-lists/${this.itemList._id}`, changedItemList);
        this.$message({ message: '아이템 리스트가 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
  },
};
</script>
