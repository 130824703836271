<style lang="less" scoped>
  .file-uploader {
    position: relative;
  }
  .file-button {
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
</style>

<template lang="pug">
.file-uploader
  input(
    :id="id",
    type="file",
    style="display: none",
    @change="upload",
    :accept="accept",
    :multiple="multiple",
  )
  label.file-button(:for="id")
    slot
</template>

<script>
export default {
  props: {
    id: { type: String, default: 'file' },
    accept: { type: String, default: null },
    multiple: { type: Boolean, default: false },
  },
  methods: {
    upload(e) {
      this.$emit('upload', e);
      e.target.value = null; // file input 초기화
    },
  },
};
</script>
