<template lang="pug">
list-layout(
  :loading="loading",
  :items="orders",
  :hasNewButton="true",
  @fetchItems="fetchOrders",
)
  template(v-slot:table-columns)
    list-table-column(label="주문코드", prop="_id")
    list-table-column(label="마켓 플레이스", prop="marketplaceType", :filter="displayMarketplaceType")
    list-table-column(label="주문 일시", prop="createdAt", :filter="displayDate")
    list-table-column(label="받는 사람 이름", prop="name")
    list-table-column(label="받는 사람 연락처", prop="phoneNumber")
    list-table-column(label="도로명 주소", prop="roadAddress")
    list-table-column(label="지번 주소", prop="jibunAddress")
    list-table-column(label="상세 주소", prop="detailAddress")
    list-table-column(label="결제금액", prop="grandTotal", :filter="displayPrice")
  template(v-slot:detail-list-contents="{ totalCount }")
    list-contents(:totalCount="totalCount")
  template(v-slot:detail-selected-contents="{ selectedItems }")
    selected-contents(
      :selectedItems="selectedItems",
      @refresh="fetchOrders({ isInit: true })",
    )
  template(v-slot:new-contents)
    new-item-contents(
      :products="products",
      @refresh="fetchOrders({ isInit: true })",
    )
  template(v-slot:footer-button-group="{ filterOptions, selectedItems }")
    list-footer-button-group(
      :q="filterOptions.q",
      :listStatus="filterOptions.listStatus",
      :from="filterOptions.from",
      :to="filterOptions.to",
      :selectedItems="selectedItems",
      :orders="orders",
      :products="products",
    )
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import ListContents from './components/ListContents';
import ListFooterButtonGroup from './components/ListFooterButtonGroup';
import SelectedContents from './components/SelectedContents';
import NewItemContents from './components/NewItemContents';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    ListLayout,
    ListTableColumn,
    ListContents,
    ListFooterButtonGroup,
    SelectedContents,
    NewItemContents,
  },
  data() {
    return {
      loading: false,
      orders: [],
      products: [],
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchOrders({ isInit, params: p }) {
      try {
        this.loading = true;
        const [listStatus] = this.$route.params.listStatus;
        let params;
        if (listStatus) params = { ...p, status: listStatus };
        else params = p;

        const { data: orders } = await this.$http.get('/crew-only/orders', { params });
        if (isInit) this.orders = orders;
        else orders.forEach((order) => this.orders.push(order));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchProducts() {
      const { data: products } = await this.$http.get('/products');
      this.products = products;
    },
    displayMarketplaceType(type) {
      if (!type) return '';
      return type;
    },
    displayDate(date) {
      if (!date) return '';
      return dayjs(date).format('LLLL');
    },
    displayPrice(price) {
      if (!price) return '';
      return `${Number(price).toLocaleString()}원`;
    },
  },
};
</script>
