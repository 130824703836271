<style lang="less" scoped>
.form-contents {
  flex: 1;
  padding: 10px;
  overflow: auto;
}
.input {
  .input();
}
.footer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
  z-index: 2;
}
.remove-button {
  .button(@color: var(--orange-1));
}
.save-button {
  .button();
  margin-left: auto;
}
</style>

<template lang="pug">
.form-contents
  .form-group
    label.label ID
    input.input(v-model="localItem._id")
  .form-group
    label.label URL
    input.input(v-model="localItem.url")
  .form-group
    label.label 만료 기간
    input.input(v-model="localItem.expiredAt", type="date")
footer.footer
  button.remove-button(@click="$emit('remove')") 삭제하기
  button.save-button(@click="submit") 저장하기
</template>

<script>
import dayjs from 'dayjs';

dayjs.locale('ko');

const DEFAULT_ITEM = {
  _id: '',
  url: '',
  expiredAt: '',
};

export default {
  emits: ['remove', 'submit'],
  props: {
    item: { type: Object, default: null },
  },
  watch: {
    item(v) {
      if (v) this.initData();
    },
  },
  data() {
    return {
      localItem: { ...DEFAULT_ITEM },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.item) {
        this.localItem = { ...this.item };
        this.localItem.expiredAt = dayjs(this.item.expiredAt).format('YYYY-MM-DD');
      }
    },
    submit() {
      this.$emit('submit', this.localItem);
    },
  },
};
</script>
