<style lang="less" scoped>
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 20px;
}
.close-button {
  position: absolute;
  top: 21px;
  left: 20px;
  width: 18px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(~@/assets/close.svg);
}
.title {
  font-size: 18px;
  line-height: 160%;
}
.contents {
  padding: 20px;
}
.half-form-group {
  width: 50%;
}
.inline-input-group {
  display: flex;
  justify-content: space-between;
  width: 200%;
  .input {
    width: calc((100% - 10px) / 2);
  }
}
.input {
  .input();
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  margin-top: 20px;
  padding: 0 10px;
  box-shadow: 0px -1px 0px 1px rgba(0, 0, 0, 0.02);
}
.button {
  .button();
  &:not(:last-child) {
    margin-right: 20px;
  }
}
</style>

<template lang="pug">
st-dialog(
  v-model="modelValue",
  @update:modelValue="$emit('update:modelValue', $event)",
)
  header.header
    button.close-button(@click="close")
    h1.title 필터
  section.contents
    .half-form-group.form-group
      label.label 상태
      st-select(v-model="localListStatus")
        option(value="") 전체
        option(value="pending") 결제 대기
        option(value="ordered") 주문됨
        option(value="canceled") 취소됨
        option(value="preparing") 준비중
        option(value="packed") 출고 대기
        option(value="in-transit") 배송중
        option(value="delivered") 배송 완료
    .half-form-group.form-group
      label.label 주문 일시 범위
      .inline-input-group
        input.input(v-model="localFrom", type="datetime-local", placeholder="주문 일시 시작")
        input.input(v-model="localTo", type="datetime-local", placeholder="주문 일시 끝")
  footer.footer
    button.button(@click="clear") 필터 초기화
    button.button(@click="submit") 적용하기
</template>

<script>
import { toRefs, ref, watch, onMounted } from 'vue';

import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';
import StDialog from '@/components/StDialog';

export default {
  components: {
    StTextarea,
    StSelect,
    StDialog,
  },
  emits: ['update:modelValue', 'clear', 'submit'],
  props: {
    modelValue: { type: Boolean, default: false },
    listStatus: { type: String, default: '' },
    from: { type: String, default: '' },
    to: { type: String, default: '' },
  },
  setup(props) {
    const { listStatus, from, to } = toRefs(props);
    const localListStatus = ref('');
    const localFrom = ref('');
    const localTo = ref('');

    onMounted(() => {
      localListStatus.value = listStatus.value;
      localFrom.value = from.value;
      localTo.value = to.value;
    });
    watch(listStatus, (value) => { localListStatus.value = value; });
    watch(from, (value) => { localFrom.value = value; });
    watch(to, (value) => { localTo.value = value; });

    return {
      localListStatus,
      localFrom,
      localTo,
    };
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    },
    clear() {
      this.$emit('clear');
      this.close();
    },
    submit() {
      this.$emit('submit', {
        listStatus: this.localListStatus,
        from: this.localFrom,
        to: this.localTo,
      });
      this.close();
    },
  },
};
</script>
