import { createWebHistory, createRouter } from 'vue-router';

import Main from '@/views/index';
import OrderList from '@/views/Orders';
import DayOffList from '@/views/DaysOff';
import CouponCodeList from '@/views/CouponCodes';
import ItemList from '@/views/Items';
import ReviewList from '@/views/Reviews';
import ShortLinkList from '@/views/ShortLinks';
import ItemListList from '@/views/ItemLists';
import QuestionList from '@/views/Questions';
import LocalSellerList from '@/views/LocalSellers';
import LocalSellerWithdrawalList from '@/views/LocalSellerWithdrawals';

import Login from '@/views/login';
import OrderSheet from '@/views/OrderSheet';
import ProductionPlan from '@/views/ProductionPlan';

export default function (store) {
  const checkAuth = async function beforeEnter(to, from, next) {
    const loginToken = window.localStorage.getItem('loginToken');
    if (loginToken) await store.dispatch('fetchMe');
    if (!store.state.me) return next('/login');
    return next();
  };

  const routes = [
    {
      path: '/',
      name: 'Main',
      redirect: '/orders',
      component: Main,
      beforeEnter: checkAuth,
      children: [
        {
          path: '/orders/:listStatus*',
          name: 'OrderList',
          component: OrderList,
        },
        {
          path: '/days-off/:listStatus*',
          name: 'DayOffList',
          component: DayOffList,
        },
        {
          path: '/coupon-codes/:listStatus*',
          name: 'CouponCodeList',
          component: CouponCodeList,
        },
        {
          path: '/items/:listStatus*',
          name: 'ItemList',
          component: ItemList,
        },
        {
          path: '/reviews/:listStatus*',
          name: 'ReviewList',
          component: ReviewList,
        },
        {
          path: '/short-links/:listStatus*',
          name: 'ShortLinkList',
          component: ShortLinkList,
        },
        {
          path: '/item-lists/:listStatus*',
          name: 'ItemListList',
          component: ItemListList,
        },
        {
          path: '/questions/:listStatus*',
          name: 'QuestionList',
          component: QuestionList,
        },
        {
          path: '/local-sellers/:listStatus*',
          name: 'LocalSellerList',
          component: LocalSellerList,
        },
        {
          path: '/local-seller-withdrawals/:listStatus*',
          name: 'LocalSellerWithdrawalList',
          component: LocalSellerWithdrawalList,
        },
      ],
    },
    { path: '/login', component: Login },
    { path: '/order-sheet', component: OrderSheet },
    { path: '/production-plan', component: ProductionPlan },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: 'active',
  });
  return router;
}
