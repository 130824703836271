<style lang="less" scoped>
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 20px;
}
.close-button {
  position: absolute;
  top: 21px;
  left: 20px;
  width: 18px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(~@/assets/close.svg);
}
.radio-button-group-wrapper {
  width: 280px;
}
.contents {
  padding: 20px;
}
.description {
  line-height: 200%;
  color: var(--black-4);
}
.button-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.button {
  .button();
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.progress-description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 200%;
  color: var(--orange-1);
}
</style>

<template lang="pug">
st-dialog(
  v-model="modelValue",
  @update:modelValue="$emit('update:modelValue', $event)",
)
  header.header
    button.close-button(@click="close")
    .radio-button-group-wrapper
      radio-button-group(v-model="type", :options="typeOptions")
  section.contents(v-if="type === 'import'")
    p.description.
      CNPLUS에 업로드 될 엑셀을 다운로드 합니다.#[br]
      개인정보를 포함하고 있으므로 외부로 유출되지 않도록 주의하십시오.
    .button-group
      button.button(@click="downloadFromList") 현재 목록 모두 다운로드
      button.button(@click="downloadFromSelected") 선택된 항목 다운로드
    p.progress-description(v-if="donwloadLoading") 다운로드 준비 중
  section.contents(v-else-if="type === 'export'")
    p.description.
      CNPLUS에서 받은 엑셀 파일을 선택해 주세요.#[br]
      엑셀 목록과 포장한 상품을 다시 한번 확인해 주세요.#[br]
      등록이 완료되면 모든 주문은 '출고 대기' 상태로 변경됩니다.#[br]
      주문의 현재 상태가 '준비 중'이 아닌 경우 무시됩니다.
    .button-group
      file-input.button(@upload="upload") CNPLUS 엑셀 업로드
    p.progress-description(v-if="uploadTotalCount") {{ uploadTotalCount }} 중 {{ uploadCurrentCount }}개 처리 중
</template>

<script>
/* eslint-disable no-restricted-syntax, no-await-in-loop */

import ExportExcel from '@/helpers/export-excel';
import ImportExcel from '@/helpers/import-excel';

import StDialog from '@/components/StDialog';
import RadioButtonGroup from '@/components/RadioButtonGroup';
import FileInput from '@/components/FileInput';

const displayAddress = (jibunAddress, roadAddress, detailAddress) => {
  if (!jibunAddress) return `${roadAddress}, ${detailAddress}`;
  return `${jibunAddress}, ${detailAddress}`;
};

const excelFormBuilder = (order) => {
  const seletedPrimaryItem = order.items[0];
  const quantityText = order.items.length > 1 ? ` 외 ${order.items.length - 1}개` : '';
  const goodsName = `[싹뚝 샤브샤브] ${seletedPrimaryItem.name}${quantityText}`;

  return {
    받는분성명: order.name,
    받는분전화번호: order.phoneNumber,
    '받는분주소(전체, 분할)': displayAddress(order.jibunAddress, order.roadAddress, order.detailAddress),
    받는분우편번호: order.zipcode,
    배송메세지1: order.deliveryRequest,
    품목명: goodsName,
    박스수량: '1',
    고객주문번호: order._id,
    운송장번호: '',
  };
};

export default {
  components: {
    StDialog,
    RadioButtonGroup,
    FileInput,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Boolean, default: false },
    selectedItems: { type: Array, default: () => [] },
    orders: { type: Array, default: () => [] },

    q: { type: String, default: '' },
    listStatus: { type: String, default: '' },
    from: { type: String, default: '' },
    to: { type: String, default: '' },
  },
  data() {
    return {
      type: 'import', // 'import', 'export'
      typeOptions: [
        { label: '엑셀 다운로드', value: 'import' },
        { label: '송장번호 일괄 등록', value: 'export' },
      ],
      donwloadLoading: false,
      uploadTotalCount: 0,
      uploadCurrentCount: 0,
      detailAddress: '',
    };
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    },
    async downloadFromList() {
      try {
        this.donwloadLoading = true;
        const { q, listStatus: status, from, to } = this;
        const params = { status, from, to, q };
        const { data: orders } = await this.$http.get('/crew-only/orders', { params });
        const excelData = orders.map((order) => excelFormBuilder(order));
        ExportExcel(excelData);
      } catch (e) {
        console.error(e);
      } finally {
        this.donwloadLoading = false;
      }
    },
    async downloadFromSelected() {
      if (this.selectedItems.length === 0) {
        this.$message({ message: '선택된 항목이 없습니다.' });
        return;
      }
      const excelData = this.selectedItems.map((order) => excelFormBuilder(order));
      ExportExcel(excelData);
    },
    async upload(e) {
      const [file] = e.target.files;
      if (!file) return;
      const rows = await ImportExcel(file);
      const payload = rows.map((row) => {
        const id = row['고객주문번호'];
        const deliveryTrackingNumbers = row['운송장번호'].replaceAll(/-/ig, '');
        return [id, deliveryTrackingNumbers];
      });
      try {
        await this.$http.post('/crew-only/cn-plus', payload);
        this.$message({ message: '송장번호가 전부 등록되었습니다.' });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
