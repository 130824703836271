<style lang="less" scoped>
.contents {
  margin-top: 5px;
}
</style>

<template lang="pug">
.description-image-item-container
  thumbnail-uploader(
    :id="`description-image-${index}`",
    :image="image",
    @upload="upload",
    @remove="$emit('remove')",
  )
  .contents
    st-textarea(
      :maxHeight="400",
      :modelValue="text",
      @update:modelValue="$emit('update:text', $event)",
    )
</template>

<script>
import ThumbnailUploader from '@/components/ThumbnailUploader';
import StTextarea from '@/components/StTextarea';

export default {
  components: {
    ThumbnailUploader,
    StTextarea,
  },
  emits: ['upload', 'remove', 'update:text'],
  props: {
    image: { type: String, default: '' },
    index: { type: Number, default: 0 },
    text: { type: String, default: '' },
  },
  methods: {
    upload($event) {
      this.$emit('upload', $event);
    },
  },
};
</script>
