<style lang="less" scoped>
.contents {
  flex: 1;
  padding: 0 10px;
  overflow: auto;
}
.item-text {
  padding: 2px 5px;
  white-space: pre-line;
  line-height: 32px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
}
.save-button {
  .button();
}
</style>

<template lang="pug">
.contents
  template(v-if="isMultiple")
    .form-group
      label.label 선택된 판매자 수
      input.input(:value="selectedItems.length", readonly)
  template(v-else-if="localSeller")
    .form-group
      label.label 인출 내역
      p.item-text {{ withdrawalsText }}
footer.footer
  button.save-button(@click="updateLocalSeller") 저장하기
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';
import StTextarea from '@/components/StTextarea';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    StTextarea,
  },
  emits: ['refresh', 'clearSelected'],
  props: {
    selectedItems: { type: Array, default: () => [] },
  },
  watch: {
    isMultiple(value) {
      if (!value) this.fetchLocalSeller();
    },
    selectedItemId() {
      this.fetchLocalSeller();
    },
  },
  computed: {
    isMultiple() {
      if (!this.selectedItems) return false;
      if (this.selectedItems.length === 1) return false;
      return true;
    },
    selectedItemId() {
      if (this.isMultiple) return null;
      return this.selectedItems[0]._id;
    },
    withdrawalsText() {
      if (!this.withdrawals) return '';
      return this.withdrawals.map((withdrawal) => {
        const { createdAt, points } = withdrawal;
        const dateText = dayjs(createdAt).format('ll');
        const pointText = points ? `${points}원` : '';
        return `${dateText} - ${pointText}`;
      }).join('\n');
    },
  },
  data() {
    return {
      localSeller: null,
      withdrawals: null,
      multipleActivated: '',
    };
  },
  mounted() {
    if (!this.isMultiple) {
      this.fetchLocalSeller();
      this.fetchWithdrawals();
    }
  },
  methods: {
    async fetchLocalSeller() {
      if (!this.selectedItems[0]) return;
      try {
        const localSellerId = this.selectedItems[0]._id;
        const { data: localSeller } = await this.$http.get(`/crew-only/local-sellers/${localSellerId}`);
        this.localSeller = localSeller;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchWithdrawals() {
      try {
        const localSellerId = this.selectedItems[0]._id;
        const { data: withdrawals } = await this.$http.get('/crew-only/local-seller-withdrawal-requests', {
          params: { q: localSellerId },
        });
        this.withdrawals = withdrawals;
      } catch (e) {
        console.error(e);
      }
    },
    async updateLocalSeller() {
      try {
        await this.$http.put(`/crew-only/local-sellers/${this.localSeller._id}`, this.localSeller);
        this.$message({ message: '판매자가 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    displayDate(date) {
      if (!date) return '';
      return dayjs(date).format('LLL');
    },
  },
};
</script>
