<style lang="less" scoped>
.form-contents {
  flex: 1;
  padding: 10px;
  overflow: auto;
}
.upload-button {
  .button();
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.input {
  .input();
}
.thumbnail-wrapper {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.draggable {
  width: 100%;
}
.footer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
  z-index: 2;
}
.remove-button {
  .button(@color: var(--orange-1));
}
.save-button {
  .button();
  margin-left: auto;
}
</style>

<template lang="pug">
.form-contents
  .form-group
    label.label 이름
    input.input(v-model="localItem.name")
  .form-group
    label.label 평점
    input.input(v-model="localItem.rating")
  .form-group
    label.label 플랫폼
    st-select(v-model="localItem.platform")
      option(value="배달의 민족") 배달의 민족
      option(value="요기요") 요기요
      option(value="쿠팡이츠") 쿠팡이츠
      option(value="싹뚝 샤브샤브") 싹뚝 샤브샤브
  .form-group
    label.label 내용
    st-textarea(v-model="localItem.content")
  .form-group
    label.label 이미지 (최대 3개)
    draggable.draggable(v-model="imagePreviews", item-key="key")
      template(#item="{ element, index }")
        .thumbnail-wrapper
          thumbnail-uploader(
            :image="element.url",
            @remove="removeImage(element.key, index)",
          )
    file-uploader.upload-button(@upload="uploadImage") 추가하기
  .form-group
    label.label 주문 목록
    st-checkbox-item(
      v-for="product in primaryProducts",
      :value="!!localItem.items.find(i => i === product._id)",
      @update:value="toggleReviewItems(product)",
    ) {{ product.name }}
  .form-group(v-if="localItem.orderId")
    label.label 주문 내역
    router-link.input(:to="{ path: '/orders', query: { q: localItem.orderId } }") 바로가기
  .form-group
    label.label 리뷰 숨기기
    st-select(v-model="localItem.hidden")
      option(:value="true") 숨기기
      option(:value="false") 보이기
  .form-group
    label.label 리뷰 생성일(예약일)
    input.input(@input="reviewCreatedAtInput", :value="reviewCreatedAt", type="datetime-local")
footer.footer
  button.remove-button(@click="$emit('remove')") 삭제하기
  button.save-button(@click="submit") 저장하기
</template>

<script>
import dayjs from 'dayjs';
import draggable from 'vuedraggable';

import ThumbnailUploader from '@/components/ThumbnailUploader';
import FileUploader from '@/components/FileUploader';
import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';
import StCheckboxItem from '@/components/StCheckboxItem';

dayjs.locale('ko');

const DEFAULT_ITEM = {
  name: '',
  rating: 5,
  images: [],
  platform: '',
  content: '',
  items: [],
  hidden: '',
  createdAt: '',
};

export default {
  components: {
    draggable,
    ThumbnailUploader,
    FileUploader,
    StTextarea,
    StSelect,
    StCheckboxItem,
  },
  emits: ['remove', 'submit'],
  props: {
    isNew: { type: Boolean, default: false },
    item: { type: Object, default: null },
    products: { type: Array, default: () => [] },
  },
  watch: {
    item(v) {
      if (v) this.initData();
    },
  },
  computed: {
    primaryProducts() {
      if (!this.products) return null;
      const products = this.products.filter((product) => product.primary === true);
      return products;
    },
    reviewCreatedAt() {
      if (!this.localItem.createdAt) return '';
      return dayjs(this.localItem.createdAt).format('YYYY-MM-DDTHH:mm');
    },
  },
  data() {
    return {
      localItem: DEFAULT_ITEM,
      imagePreviews: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.item) {
        this.localItem = { ...this.item };
        this.imagePreviews = this.localItem.images.map((key, index) => {
          const url = this.localItem.imageUrls[index];
          return { key, url };
        });
      }
    },
    async upload($event) {
      try {
        const apiType = 'review';
        const { url, key } = await this.$store.dispatch('upload', { $event, apiType });
        this.$message({ message: '이미지가 업로드 되었습니다' });
        return { url, key };
      } catch (error) {
        return this.$message({ message: '이미지가 업로드 되지 못했습니다. 이미지 크기나 이미지 타입을 확인 해주세요' });
      }
    },
    removeImage(imageKey, previewIndex) {
      const targetImageIndex = this.localItem.images.findIndex((k) => imageKey === k);
      this.localItem.images.splice(targetImageIndex, 1);
      this.imagePreviews.splice(previewIndex, 1);
    },
    async uploadImage($event) {
      try {
        const { url, key } = await this.upload($event);
        this.localItem.images.push(key);
        this.imagePreviews.push({ url, key });
      } catch (e) {
        console.error(e);
      }
    },
    reviewCreatedAtInput($event) {
      this.localItem.createdAt = $event.target.value;
      return this.localItem.createdAt;
    },
    toggleReviewItems(product) {
      const duplicatedIndex = this.localItem.items.findIndex((i) => i === product._id);
      if (duplicatedIndex === -1) this.localItem.items.push(product._id);
      else this.localItem.items.splice(duplicatedIndex, 1);
    },
    submit() {
      this.localItem.images = this.imagePreviews.map(({ key }) => key);
      this.localItem.rating = Number(this.localItem.rating);
      this.$emit('submit', {
        ...this.localItem,
        createdAt: dayjs(this.localItem.createdAt).toDate(),
      });
    },
  },
};
</script>
