<style lang="less" scoped>
.detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.contents {
  flex: 1;
  padding: 10px;
  overflow: auto;
}
.input {
  .input();
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
}
.save-button {
  .button();
}
</style>

<template lang="pug">
.detail-container
  .contents
    .form-group
      label.label 코드
      input.input(v-model="code")
    .form-group
      label.label 이름
      input.input(v-model="name")
    .form-group
      label.label 할인 금액
      input.input(v-model="discountAmount")
    .form-group
      label.label 코드 만료기간
      input.input(v-model="codeValidUntil", type="date")
    .form-group
      label.label 쿠폰 만료기간
      input.input(v-model="couponValidUntil", type="date")
    .form-group
      label.label 발급 후 n일 뒤 만료
      input.input(v-model="expiresAfterIssuance")
    .form-group
      label.label 쿠폰 코드 타입
      st-select(v-model="type")
        option(value="once") 쿠폰 1개
        option(value="once-per-user") 사용자당 1개
  footer.footer
    button.save-button(@click="submit") 저장하기
</template>

<script>
import dayjs from 'dayjs';
import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';

dayjs.locale('ko');

export default {
  components: {
    StTextarea,
    StSelect,
  },
  emits: ['refresh'],
  data() {
    return {
      code: '',
      name: '',
      discountAmount: '',
      codeValidUntil: '',
      couponValidUntil: '',
      expiresAfterIssuance: '',
      type: '',
    };
  },
  methods: {
    async submit() {
      try {
        await this.$http.post('/crew-only/coupon-codes', {
          code: this.code,
          name: this.name,
          discountAmount: this.discountAmount,
          codeValidUntil: this.codeValidUntil,
          couponValidUntil: this.couponValidUntil,
          expiresAfterIssuance: this.expiresAfterIssuance,
          type: this.type,
        });
        this.$message({ message: '쿠폰이 생성 되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
  },
};
</script>
