<style lang="less" scoped>
.list-detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  height: 48px;
  padding: 10px;
}
</style>

<template lang="pug">
aside.list-detail-container
  header.header
    radio-button-group(v-model="type", :options="typeOptions")
  slot(v-if="type === 'list'", name="list")
  slot(v-else-if="type === 'selected'", name="selected")
</template>

<script>
import RadioButtonGroup from '@/components/RadioButtonGroup';

export default {
  components: {
    RadioButtonGroup,
  },
  data() {
    return {
      type: 'selected', // 'list', 'selected'
      typeOptions: [
        { label: '목록 자세히', value: 'list' },
        { label: '선택 자세히', value: 'selected' },
      ],
    };
  },
};
</script>
