<template lang="pug">
list-layout(
  :hasFilter="false",
  :hasNewButton="true",
  :loading="loading",
  :items="shortLinks",
  @fetchItems="fetchShrotLinks",
)
  template(v-slot:table-columns)
    list-table-column(label="ID", prop="_id")
    list-table-column(label="URL", prop="url")
    list-table-column(label="만료 기간", prop="expiredAt", :filter="displayDate")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems, clearSelected }")
    selected-contents(
      :selectedItems="selectedItems",
      @refresh="fetchShrotLinks({ isInit: true })",
      @clearSelected="clearSelected",
    )
  template(v-slot:new-contents)
    new-item-contents(
      @refresh="fetchShrotLinks({ isInit: true })",
    )
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';
import NewItemContents from './components/NewItemContents';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
    NewItemContents,
  },
  data() {
    return {
      loading: false,
      shortLinks: [],
    };
  },
  methods: {
    async fetchShrotLinks({ isInit }) {
      try {
        this.loading = true;
        const { data: shortLinks } = await this.$http.get('/crew-only/short-links');
        if (isInit) this.shortLinks = shortLinks;
        else shortLinks.forEach((review) => this.shortLinks.push(review));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    displayDate(date) {
      if (!date) return '';
      return dayjs(date).format('LL');
    },
  },
};
</script>
