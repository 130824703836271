<template lang="pug">
td(:label="label", :prop="prop")
  slot(:row="row")
</template>

<script>
export default {
  props: {
    row: { type: Object, default: null },
    label: { type: String, default: '' },
    prop: { type: String, default: '' },
  },
};
</script>
