<style lang="less" scoped>
.table-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.table {
  .table();
}
.checkbox-cell {
  width: 20px;
  padding: 0 4px !important;
}
.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}
</style>

<template lang="pug">
.table-wrapper
  table.table
    thead
      tr
        th.checkbox-cell.sticky
          .checkbox-wrapper
            st-checkbox(
              :value="isAllSelected",
              @click.native="$emit('toggleAllSelectedItems')",
            )
        th(v-for="label in labels", :key="label") {{ label }}
    tbody
      tr(
        v-for="row in filteredRows",
        :key="row._id",
        :class="{ active: !!selectedItems.find(o => o._id === row.originItem._id) }",
      )
        td.checkbox-cell.sticky
          .checkbox-wrapper
            st-checkbox(
              :value="!!selectedItems.find(o => o._id === row.originItem._id)",
              @click.native="$emit('toggleSelectedItem', row.originItem)",
            )
        list-table-column(
          v-for="(cell, key, cellIndex) in row.item",
          :key="cellIndex",
          :row="row.item",
          @click="$emit('goToDetail', row.originItem)"
        ) {{ filterMap[key] ? filterMap[key](cell) : cell }}
    slot(name="table-columns")
</template>

<script>
// TODO: _id 말고 unique id를 prop으로 따로 받아서 처리 필요
import _ from 'lodash';
import StCheckbox from '@/components/StCheckbox';
import ListTableColumn from './ListTableColumn';

export default {
  components: {
    StCheckbox,
    ListTableColumn,
  },
  emits: [
    'toggleAllSelectedItems',
    'toggleSelectedItem',
    'goToDetail',
  ],
  props: {
    items: { type: Array, default: () => [] },
    selectedItems: { type: Array, default: () => [] },
    isAllSelected: { type: Boolean, default: false },
  },
  computed: {
    filteredRows() {
      if (!this.props) return [];
      return this.items.map((item) => {
        const row = {};
        const newItem = {};
        this.props.forEach((prop) => {
          newItem[prop] = _.get(item, prop);
        });
        row.originItem = item;
        row.item = newItem;
        return row;
      });
    },
  },
  data() {
    return {
      labels: [],
      props: [],
      filterMap: {},
    };
  },
  mounted() {
    // TODO: 개선 필요
    this.$slots['table-columns']()[0].children.forEach((slot) => {
      const { label, prop, filter } = slot.props;
      this.labels.push(label);
      this.props.push(prop);
      if (filter) this.filterMap[prop] = filter;
    });
    // TODO: 기존 slot 제거 로직 필요
    // this.$slots.tableColumn = [];
    // this.$forceUpdate();
  },
};
</script>
