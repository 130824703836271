<style lang="less" scoped>
.contents {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
</style>

<template lang="pug">
.contents
  template(v-if="isMultiple")
    .form-group
      label.label 선택된 단축 URL 갯수
      input.input(:value="selectedItems.length", readonly)
    .form-group
      label.label 활성화
      st-select(v-model="multipleActivated")
        option(:value="true") true
        option(:value="false") false
  template(v-else-if="shortLink")
    form-contents(
      :products="products",
      :item="shortLink",
      @submit="submitShortLink",
      @remove="removeShortLink",
    )
</template>

<script>
import StSelect from '@/components/StSelect';
import FormContents from './FormContents';

export default {
  components: {
    StSelect,
    FormContents,
  },
  emits: ['refresh', 'clearSelected'],
  props: {
    selectedItems: { type: Array, default: () => [] },
    products: { type: Object, default: null },
  },
  watch: {
    isMultiple(value) {
      if (!value) this.fetchShortLink();
    },
    selectedItemId() {
      this.fetchShortLink();
    },
  },
  computed: {
    isMultiple() {
      if (!this.selectedItems) return false;
      if (this.selectedItems.length === 1) return false;
      return true;
    },
    selectedItemId() {
      if (this.isMultiple) return null;
      return this.selectedItems[0]._id;
    },
  },
  data() {
    return {
      shortLink: null,
      multipleActivated: '',
    };
  },
  mounted() {
    if (!this.isMultiple) this.fetchShortLink();
  },
  methods: {
    async fetchShortLink() {
      if (!this.selectedItems[0]) return;
      try {
        const shortLinkId = this.selectedItems[0]._id;
        const { data: shortLink } = await this.$http.get(`/crew-only/short-links/${shortLinkId}`);
        this.shortLink = shortLink;
      } catch (e) {
        console.error(e);
      }
    },
    async removeShortLink() {
      if (!window.confirm('단축 URL을 삭제하시겠습니까?')) return;
      try {
        await this.$http.delete(`/crew-only/short-links/${this.shortLink._id}`);
        this.$emit('clearSelected');
        this.$message({ message: '단축 URL이 삭제되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    submitShortLink(changedShortLink) {
      if (this.isMultiple) this.updateMultipleShortLinks();
      else this.updateShortLink(changedShortLink);
    },
    async updateMultipleShortLinks() {
      try {
        const payload = { activated: this.multipleActivated };
        const itemIds = this.selectedItems.map((item) => item._id).join(',');
        await this.$http.put('/crew-only/short-links', payload, { params: { ids: itemIds } });
        this.$message({ message: '선택된 단축 URL들이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
    async updateShortLink(changedShortLink) {
      try {
        await this.$http.put(`/crew-only/short-links/${this.shortLink._id}`, changedShortLink);
        this.$message({ message: '단축 URL이 수정되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        if (e.response && e.response.data) this.$message.error(e.response.data.message);
      }
    },
  },
};
</script>
