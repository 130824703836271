import 'element-plus/dist/index.css';
import '@/styles/normalize.css';
import '@/styles/index.css';
import '@/styles/mixins/index.less';
import '@/styles/element-ui-override.less';
import '@/styles/global.less';

import ElementPlus, { ElMessage } from 'element-plus';
import axios from 'axios';
import { createApp } from 'vue';

import App from '@/App';
import router from '@/router';
import store from '@/store';

const loginToken = window.localStorage.getItem('loginToken');

if (loginToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${loginToken}`;
}

const s = store(axios);
const r = router(s);
const app = createApp(App);

axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE || 'https://api.stage.saktuk.com'}`;
app.config.globalProperties.$http = axios;
app.config.globalProperties.$message = (props) => (
  ElMessage({
    ...props,
    customClass: 'st-custom-el-message',
  })
);

app
  .use(s)
  .use(r)
  .use(ElementPlus);

app.mount('#app');
