<style lang="less" scoped>
.detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.contents {
  flex: 1;
  padding: 10px;
  overflow: auto;
}
.input {
  .input();
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  box-shadow: 0px -1px 0px #E6E8E7, 0px 4px 32px rgba(77, 117, 87, 0.05);
  padding: 0 8px;
}
.save-button {
  .button();
}
</style>

<template lang="pug">
.detail-container
  .contents
    .form-group
      label.label 날짜
      input.input(v-model="date", type="date")
    .form-group
      label.label 설명
      input.input(v-model="note")
    .form-group
      label.label 활성화
      st-select(v-model="activated")
        option(:value="true") true
        option(:value="false") false
  footer.footer
    button.save-button(@click="submit") 저장하기
</template>

<script>
import dayjs from 'dayjs';
import StTextarea from '@/components/StTextarea';
import StSelect from '@/components/StSelect';

dayjs.locale('ko');

export default {
  components: {
    StTextarea,
    StSelect,
  },
  emits: ['refresh'],
  data() {
    return {
      date: '',
      note: '',
      activated: true,
    };
  },
  methods: {
    async submit() {
      try {
        await this.$http.post('/crew-only/days-off', {
          _id: this.date,
          note: this.note,
          activated: this.activated,
        });
        this.$message({ message: '휴일이 등록되었습니다.' });
        this.$emit('refresh');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
