<style lang="less" scoped>
.footer-button {
  .button();
  &:not(:first-child) {
    margin-left: 8px;
  }
}
</style>

<template lang="pug">
button.footer-button(@click="openOrderDialog") 주문서
button.footer-button(@click="openDeliveryDialog") 대한통운 연동
button.footer-button(@click="openBulkAddDialog") 대량 추가

delivery-service-dialog(
  v-model="deliveryDialogVisibility",
  :orders="orders",
  :selectedItems="selectedItems",
  :q="q",
  :listStatus="listStatus",
  :from="from",
  :to="to",
)
order-sheet-dialog(
  v-model="orderDialogVisibility",
  @printAllProductionPlanFromList="printAllProductionPlanFromList",
  @printAllProductionPlanFromSelected="printAllProductionPlanFromSelected",
  @printAllOrderSheetFromList="printAllOrderSheetFromList",
  @printAllOrderSheetFromSelected="printAllOrderSheetFromSelected",
)
bulk-add-dialog(
  v-model="bulkAddDialogVisibility",
  :products="products",
)
</template>

<script>
import DeliveryServiceDialog from './DeliveryServiceDialog';
import OrderSheetDialog from './OrderSheetDialog';
import BulkAddDialog from './BulkAddDialog';

export default {
  components: {
    DeliveryServiceDialog,
    OrderSheetDialog,
    BulkAddDialog,
  },
  props: {
    q: { type: String, default: '' },
    listStatus: { type: String, default: '' },
    from: { type: String, default: '' },
    to: { type: String, default: '' },
    selectedItems: { type: Array, default: () => [] },
    orders: { type: Array, default: () => [] },
    products: { type: Array, default: () => [] },
  },
  data() {
    return {
      orderDialogVisibility: false,
      deliveryDialogVisibility: false,
      bulkAddDialogVisibility: false,
    };
  },
  methods: {
    openOrderDialog() {
      this.orderDialogVisibility = true;
    },
    openDeliveryDialog() {
      this.deliveryDialogVisibility = true;
    },
    openBulkAddDialog() {
      this.bulkAddDialogVisibility = true;
    },
    printAllProductionPlanFromList() {
      const resolvedRoute = this.$router.resolve({ path: '/production-plan', query: this.$route.query });
      window.open(resolvedRoute.href, '_blank');
    },
    printAllProductionPlanFromSelected() {
      const selectedOrderIds = this.selectedItems.map((order) => order._id);
      const resolvedRoute = this.$router.resolve({ path: '/production-plan', query: { oIds: selectedOrderIds } });
      window.open(resolvedRoute.href, '_blank');
    },
    printAllOrderSheetFromList() {
      const resolvedRoute = this.$router.resolve({ path: '/order-sheet', query: this.$route.query });
      window.open(resolvedRoute.href, '_blank');
    },
    printAllOrderSheetFromSelected() {
      const selectedOrderIds = this.selectedItems.map((order) => order._id);
      const resolvedRoute = this.$router.resolve({ path: '/order-sheet', query: { oIds: selectedOrderIds } });
      window.open(resolvedRoute.href, '_blank');
    },
  },
};
</script>
