<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #F8FBF9;
}
.form {
  width: 440px;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 32px rgba(77, 117, 87, 0.05);
  border-radius: 12px;
}
.input {
  .input();
  width: 100%;
}
.button {
  .button();
  margin-top: 20px;
}
</style>

<template lang="pug">
section.container
  form.form(@submit.prevent="login")
    img.logo(src="@/assets/logo.svg")
    .form-group
      label.label 임시 접속용 토큰
      input.input(v-model="loginToken", placeholder="임시 접속용 토큰")
    button.button 들어가기
</template>

<script>
export default {
  data() {
    return {
      loginToken: '',
    };
  },
  methods: {
    async login() {
      window.localStorage.setItem('loginToken', this.loginToken);
      this.$http.defaults.headers.common.Authorization = `Bearer ${this.loginToken}`;
      try {
        const { data } = await this.$http.get('/crew-only/me');
        this.$store.commit('SET_ME', data);
        this.$router.push('/');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
