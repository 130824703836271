<template lang="pug">
list-layout(
  :hasFilter="false",
  :loading="loading",
  :items="localSellers",
  @fetchItems="fetchQuestions",
)
  template(v-slot:table-columns)
    list-table-column(label="이름", prop="name")
    list-table-column(label="연락처", prop="username")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems, clearSelected }")
    selected-contents(
      :selectedItems="selectedItems",
      @refresh="fetchQuestions({ isInit: true })",
      @clearSelected="clearSelected",
    )
</template>

<script>
import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
  },
  data() {
    return {
      loading: false,
      localSellers: [],
    };
  },
  methods: {
    async fetchQuestions({ isInit, params }) {
      try {
        this.loading = true;
        const { data: localSellers } = await this.$http.get('/crew-only/local-sellers', { params });
        if (isInit) this.localSellers = localSellers;
        else localSellers.forEach((localSeller) => this.localSellers.push(localSeller));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
