<template lang="pug">
list-layout(
  :hasFilter="false",
  :hasNewButton="true",
  :loading="loading",
  :items="dayOffs",
  @fetchItems="fetchDayOffs",
)
  template(v-slot:table-columns)
    list-table-column(label="일자", prop="_id", :filter="displayDate")
    list-table-column(label="설명", prop="note")
    list-table-column(label="활성화", prop="activated")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems }")
    selected-contents(
      :selectedItems="selectedItems",
      @refresh="fetchDayOffs({ isInit: true })",
    )
  template(v-slot:new-contents)
    new-item-contents(@refresh="fetchDayOffs({ isInit: true })")
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';
import NewItemContents from './components/NewItemContents';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
    NewItemContents,
  },
  data() {
    return {
      loading: false,
      dayOffs: [],
    };
  },
  methods: {
    async fetchDayOffs({ isInit, params }) {
      try {
        this.loading = true;
        const { data: dayOffs } = await this.$http.get('/crew-only/days-off', { params });
        if (isInit) this.dayOffs = dayOffs;
        else dayOffs.forEach((dayOff) => this.dayOffs.push(dayOff));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    displayDate(date) {
      return dayjs(date).format('YYYY-MM-DD (dd)');
    },
  },
};
</script>
