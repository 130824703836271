<style lang="less" scoped>
.st-checkbox-item-container {
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
}
.contents {
  margin-left: 10px;
}
</style>

<template lang="pug">
.st-checkbox-item-container(@click="update")
  st-checkbox(:value="value")
  .contents
    slot
</template>

<script>
import StCheckbox from '@/components/StCheckbox';

export default {
  components: {
    StCheckbox,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  emits: ['update:value'],
  methods: {
    update() {
      this.$emit('update:value', !this.value);
    },
  },
};
</script>
