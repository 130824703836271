<template lang="pug">
list-layout(
  :hasFilter="false",
  :loading="loading",
  :items="questions",
  @fetchItems="fetchQuestions",
)
  template(v-slot:table-columns)
    list-table-column(label="상태", prop="status", :filter="displayStatus")
    list-table-column(label="작성일", prop="createdAt", :filter="displayDate")
    list-table-column(label="문의 내용", prop="content", :filter="displayContent")
    list-table-column(label="마켓 플레이스", prop="marketplaceType")
  template(v-slot:detail-list-contents)
    list-contents
  template(v-slot:detail-selected-contents="{ selectedItems, clearSelected }")
    selected-contents(
      :selectedItems="selectedItems",
      @refresh="fetchQuestions({ isInit: true })",
      @clearSelected="clearSelected",
    )
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ko';

import ListLayout from '@/components/layout/ListLayout';
import ListTableColumn from '@/components/layout/ListTableColumn';
import SelectedContents from './components/SelectedContents';
import ListContents from './components/ListContents';

dayjs.extend(localizedFormat);
dayjs.tz.setDefault('Asia/Seoul');
dayjs.locale('ko');

export default {
  components: {
    ListLayout,
    ListTableColumn,
    SelectedContents,
    ListContents,
  },
  data() {
    return {
      loading: false,
      questions: [],
    };
  },
  methods: {
    async fetchQuestions({ isInit, params }) {
      try {
        this.loading = true;
        const { data: questions } = await this.$http.get('/crew-only/questions', { params });
        if (isInit) this.questions = questions;
        else questions.forEach((question) => this.questions.push(question));
        // TODO: paiging 추후에 처리하면서 this.totalCount 처리
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    displayContent(content) {
      if (content.length > 30) return `${content.slice(0, 30)}...`;
      return content;
    },
    displayDate(date) {
      if (!date) return '';
      return dayjs(date).format('LLL');
    },
    displayStatus(status) {
      if (status === 'unanswered') return '미답변';
      if (status === 'unconfirmed') return '미확인';
      if (status === 'complete') return '처리완료';
      return '상태 없음';
    },
  },
};
</script>
